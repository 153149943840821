import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import DoneIcon from '@material-ui/icons/Done';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';


import { StressProfileMaster } from '../components/Master';

import { withCommonStyles } from './Style';

const useStyles = makeStyles(theme => withCommonStyles(theme, {
  doc_title_bar: {
    margin: theme.spacing(0, 0, 2),
  },
  doc_title: {
    flexGrow: 1,
  },
  box: {
    margin: theme.spacing(2, 0, 2),
  },
  doc_box: {
    margin: theme.spacing(4, 0, 4),
  },
  alert_box: {
    padding: theme.spacing(2),
    background: theme.palette.secondary.main,
  },
  profile_section: {
    margin: theme.spacing(4, 0, 2),
    padding: theme.spacing(0, 2, 0),
  },
  profile_section_title: {
    margin: theme.spacing(1, 0, 1),
  },
  name_col: {
    minWidth: '200px',
    maxWidth: '200px',
  },
  rank_col: {
    textAlign: 'center',
    minWidth: '50px',
    maxWidth: '50px',
    borderLeft: '1px solid',
    borderColor: theme.palette.grey[300],
  }
}));

export const StressProfilePaper = ({ user, pid, project, sid, subject, detail }) => {
  const classes = useStyles();

  if (!(detail && detail.stress_profile)) {
    return null;
  }

  return (
    
    <Paper>
      <Box className={classes.box + ' doc_fields_box'}>
        <Box className={classes.doc_title_bar}>
          <Toolbar>
            <Typography className={classes.doc_title}>ストレスプロフィール</Typography>
          </Toolbar>
          <Divider />
        </Box>
        {StressProfileMaster.map((section, si) => (
          <Box className={classes.profile_section} key={si}>
            <Typography className={classes.profile_section_title} variant="subtitle2">{`${section.id}: ${section.text}`}</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.name_col}>&nbsp;</TableCell>
                  <TableCell className={classes.rank_col}>低い／少ない</TableCell>
                  <TableCell className={classes.rank_col}>やや低い／少ない</TableCell>
                  <TableCell className={classes.rank_col}>普通</TableCell>
                  <TableCell className={classes.rank_col}>やや高い／多い</TableCell>
                  <TableCell className={classes.rank_col}>高い／多い</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {section.profiles.map((profile, pi) => (
                  <TableRow key={pi}>
                    <Tooltip placement="right" title={profile.type === 0 ? "高いほど高ストレス" : "低いほど高ストレス"}>
                      <TableCell className={classes.name_col}>{profile.text}</TableCell>
                    </Tooltip>
                    <TableCell className={classes.rank_col}><ProfileCell value={detail.stress_profile[profile.id]} type={profile.type} rank={1} /></TableCell>
                    <TableCell className={classes.rank_col}><ProfileCell value={detail.stress_profile[profile.id]} type={profile.type} rank={2} /></TableCell>
                    <TableCell className={classes.rank_col}><ProfileCell value={detail.stress_profile[profile.id]} type={profile.type} rank={3} /></TableCell>
                    <TableCell className={classes.rank_col}><ProfileCell value={detail.stress_profile[profile.id]} type={profile.type} rank={4} /></TableCell>
                    <TableCell className={classes.rank_col}><ProfileCell value={detail.stress_profile[profile.id]} type={profile.type} rank={5} /></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        ))}
      </Box>
    </Paper>
  );
}

const ProfileCell = ({value, type, rank}) => {
  // typeが0のものはvalueを反転する
  const v = (type === 0) ? 6 - value : value;
  const content = (v === rank) ? (<DoneIcon />) : '';
  return content;
}

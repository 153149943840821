import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
// import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Copyright } from '../components/Copyright';
import { Link, useHistory } from 'react-router-dom'
import firebase from 'firebase/app';
import Messagebar from '../components/Messagebar';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const SignUp = () => {
  const classes = useStyles();
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log('createUserWithEmailAndPassword');
      const data = await firebase.auth().createUserWithEmailAndPassword(email, password);

      await data.user.updateProfile({
        displayName: name.trim(),
      });

      // await firebase.firestore().collection("users").doc(data.user.uid).set({
      //   firstName: firstName.trim(),
      //   lastName: lastName.trim(),
      //   email: email.trim(),
      // });

      history.push('/user/profile');
      console.log('handleSubmit: end');

    } catch (error) {
      console.log('ERROR: handleSubmit', error.code, error.message);
      setError(error.code);
    }
  }

  // db.collection('users').doc(uid)
  // db.collection('organizations').doc(orgid)
  // db.collection('projects').doc(projectid).collection('members').doc(member_email)

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          サインアップ
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="name"
                label="氏名"
                name="name"
                autoComplete="name"
                autoFocus
                onChange={(e) => { setName(e.target.value) }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Emailアドレス"
                name="email"
                autoComplete="email"
                onChange={(e) => { setEmail(e.target.value) }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="パスワード"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => { setPassword(e.target.value) }}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
          >
            サインアップ
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link to='/user/profile' variant="body2">
                サインイン
                </Link>
            </Grid>
          </Grid>
        </form>

        <Messagebar code={error} onClose={() => setError('')} />

      </div>
      <Box mt={5}>
        <Copyright />
      </Box>


    </Container>
  );
}

import { useState, useEffect } from 'react';


export const useMessage = (name, id) => {
  const [qinfo, setQinfo] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_APIHOST}/${name}/${id}`);
        const data = await res.json();
        setQinfo(data);
      } catch (error) {
        setQinfo(null);
        // TODO: APIエラー処理
        console.log(error);
      }
    }
    fetchData();
  }, [name, id]);
  return { qinfo }
}
import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Copyright } from '../components/Copyright';
import { Link } from 'react-router-dom'
import firebase from 'firebase/app';
import Messagebar from '../components/Messagebar';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const PasswordReset = () => {
  const classes = useStyles();
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log('sendPasswordResetEmail');
      await firebase.auth().sendPasswordResetEmail(email, null);
      console.log('handleSubmit: end');
      setStatus(true);
    } catch (error) {
      console.log('ERROR: handleSubmit', error.code, error.message);
      setError(error.code);
    }
  }

  let body = (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1">パスワードをリセットするための確認メールを送信しました。</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">送信先：{email}</Typography>
        </Grid>
      </Grid>
      <Grid container justify="flex-end">
        <Grid item>
          <Link to='/user/profile' variant="body2">
            サインイン
          </Link>
        </Grid>
      </Grid>
    </div>
  );
  if (!status) {
    body = (
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">パスワードをリセットするための確認メールを送信します。登録済みのEmailアドレスを入力してください。</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Emailアドレス"
              name="email"
              autoComplete="email"
              onChange={(e) => { setEmail(e.target.value) }}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
        >
          リセット
      </Button>
        <Grid container justify="flex-end">
          <Grid item>
            <Link to='/user/profile' variant="body2">
              サインイン
            </Link>
          </Grid>
        </Grid>
      </form>
    );
  }


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          パスワードリセット
        </Typography>

        {body}

        <Messagebar code={error} onClose={() => setError('')} />
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}

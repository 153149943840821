import firebase from 'firebase/app';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import AddIcon from '@material-ui/icons/Add';
import { ButtonMenu } from './ButtonMenu';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';

import { TimestampFormat, DateFormat } from './Format';

const useStyles = makeStyles(theme => ({
  root: {
  },
  button: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  doc_box: {
    padding: theme.spacing(0, 0, 2),
  },
  doc_section_box: {
    margin: theme.spacing(4, 0, 4),
  },
  doc_section_header_box: {
    margin: theme.spacing(2, 0, 2),
  },
  doc_section_body_box: {
    margin: theme.spacing(4, 2, 4),
  },
  doc_fields_grid_item_value: {
    whiteSpace: 'pre-line',
  },
  action_button_box: {
    margin: theme.spacing(2),
  },
  formControl: {
    paddingLeft: theme.spacing(2),
  },
  fieldLabel: {
    fontWeight: "bold",
    marginLeft: theme.spacing(-2),
  },
  fieldContents: {
    paddingRight: theme.spacing(2),
  },
  textFieldLabel: {
    marginBottom: theme.spacing(2),
  },
  command: {
    padding: theme.spacing(1, 1, 1, 1),
  },
}));

export const ReportListPaper = ({ pid, sid, subject, reports }) => {
  const classes = useStyles();
  const history = useHistory();
  const [del, setDel] = useState(null);

  if (!pid || !sid || !subject || !reports || reports.length === 0) {
    return null;
  }

  return (
    <Paper>
      <Box className={classes.command} display="flex" flexDirection="row-reverse">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => history.push('/project/' + pid + '/subject/' + sid + '/report/create')}
          startIcon={<AddIcon />}
        >
          面接指導結果報告書を追加
            </Button>
      </Box>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">実施年月日</TableCell>
            <TableCell align="left">就業区分</TableCell>
            <TableCell align="left">本人への指導区分</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.map((report, i) => (
            <TableRow key={report.id} hover onClick={() => history.push(`/project/${pid}/subject/${sid}/report/${report.id}`)}>
              <TableCell component="th" scope="row">
                {DateFormat(report.data().jisshi_date)}
              </TableCell>
              <TableCell>{report.data().shuugyou_kubun}</TableCell>
              <TableCell>{report.data().shidou_kubun.join('、')}</TableCell>
              <TableCell align="right">
                <ButtonMenu id={`report-list-menu-${i}`}>
                  <MenuItem onClick={e => { history.push('/project/' + pid + '/subject/' + sid + '/report/' + report.id) }}>詳細</MenuItem>
                  <MenuItem onClick={e => { history.push('/project/' + pid + '/subject/' + sid + '/report/' + report.id + '/update') }}>変更</MenuItem>
                  <MenuItem onClick={e => { setDel(report) }}>削除</MenuItem>
                </ButtonMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <DeleteDialog pid={pid} sid={sid} subject={subject} report={del && del.data()} open={del !== null} onClose={() => setDel(null)} />
    </Paper>
  );
}

export const ReportPaper = ({ pid, sid, subject, detail, rid, report }) => {
  const classes = useStyles();
  const history = useHistory();
  const [del, setDel] = React.useState(null);

  return (
    <Paper>
      <Box className={classes.doc_box}>
        <ReportInfo classes={classes} subject={subject} detail={detail} report={report} />

        <Divider />
        <Box className={classes.action_button_box}>
          <Grid container className={'doc_actions_box_grid_container'} spacing={1}>
            <Grid item><Button onClick={() => history.push('/project/' + pid + '/subject/' + sid)}>キャンセル</Button></Grid>
            <Grid item><Button color="primary" onClick={e => { console.log(e); setDel(subject) }}>削除</Button></Grid>
            <Grid item><Button color="primary" onClick={() => history.push('/project/' + pid + '/subject/' + sid + '/report/' + rid + '/update')}>変更</Button></Grid>
          </Grid>
        </Box>
        <DeleteDialog pid={pid} sid={sid} subject={del} rid={rid} report={report} open={del !== null} onClose={() => setDel(null)} />
      </Box>
    </Paper>
  );
}



export const ReportCreatePaper = ({ pid, sid, subject, detail }) => {
  const classes = useStyles();
  const history = useHistory();

  const onSubmit = async (data) => {
    await firebase.firestore()
      .collection('projects').doc(pid)
      .collection('subjects').doc(sid)
      .collection('reports')
      .add(
        {
          ...data,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
        }
      );
    history.push('/project/' + pid + '/subject/' + sid);
  }

  return (
    <Paper>
      <Box className={classes.doc_box}>
        <ReportForm classes={classes} subject={subject} detail={detail} report={reportDefault} onSubmit={onSubmit} />

        <Divider />
        <Box className={classes.action_button_box}>
          <Grid container className={'doc_actions_box_grid_container'} spacing={1}>
            <Grid item><Button onClick={() => history.push('/project/' + pid + '/subject/' + sid)}>キャンセル</Button></Grid>
            <Grid item><Button color="primary" form="reportForm" type="submit">決定</Button></Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}

export const ReportUpdatePaper = ({ pid, sid, subject, detail, rid, report }) => {
  const classes = useStyles();
  const history = useHistory();

  const onSubmit = async (data) => {
    await firebase.firestore()
      .collection('projects').doc(pid)
      .collection('subjects').doc(sid)
      .collection('reports').doc(rid)
      .set(
        {
          ...data,
        },
        { merge: true }
      );
    history.push('/project/' + pid + '/subject/' + sid);
    console.log(data);
  }

  return (
    <Paper>
      <Box className={classes.doc_box}>
        <ReportForm classes={classes} subject={subject} detail={detail} report={report} onSubmit={onSubmit} />

        <Divider />
        <Box className={classes.action_button_box}>
          <Grid container className={'doc_actions_box_grid_container'} spacing={1}>
            <Grid item><Button onClick={() => history.push('/project/' + pid + '/subject/' + sid)}>キャンセル</Button></Grid>
            <Grid item><Button color="primary" form="reportForm" type="submit">決定</Button></Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}


const DeleteDialog = ({ pid, sid, subject, rid, report, open, onClose }) => {
  const history = useHistory();

  const onSubmit = async (e) => {
    try {
      await firebase.firestore().collection('projects').doc(pid)
        .collection('subjects').doc(sid).collection('reports').doc(rid).delete();
    } catch (error) {
      console.log(error);
    }
    onClose();
    history.push(`/project/${pid}/subject/${sid}`);
  };

  if (!pid || !sid || !subject || !report) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">面接指導結果報告書の削除</DialogTitle>
      <DialogContent>
        <DialogContentText>
          面接指導結果報告書を削除します。間違いがないかを確認して、決定ボタンを押してください。
        </DialogContentText>
        <DialogContentText>
          {subject.name} ({subject.email})
        </DialogContentText>
        <DialogContentText>
          実施日 {DateFormat(report.jisshi_date)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button color="primary" onClick={onSubmit}> 決定</Button>
      </DialogActions>
    </Dialog >
  );
}

export const ReportSubjectDetailPaper = (props) => {
  const classes = useStyles();
  const subject = props.subject;
  const detail = props.detail;

  return (
    <Paper>
      <Box className={classes.doc_section_box}>
        <Box className={classes.doc_section_header_box} color="primary.contrastText" bgcolor="primary.main" p={2}>
          <Typography>対象者情報</Typography>
        </Box>
        <Box className={classes.doc_section_body_box}>
          <Grid container className={'doc_fields_grid_container'} spacing={2}>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>氏名:</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{subject.name}</Grid>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>所属:</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}></Grid>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>Emailアドレス:</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{subject.email}</Grid>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>性別:</Grid>
            {(() => {
              let value = '';
              if (subject.sex === 'male') {
                value = '男性';
              } else if (subject.sex === 'female') {
                value = '女性';
                // } else if (subject.sex === 'other') {
                //   value = 'その他';
              }
              return (<Grid item className={classes.doc_fields_grid_item_value} xs={9}>{value}</Grid>);
            })()}
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>年齢:</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}></Grid>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>勤務の状況(労働時間、労働時間以外の要因):</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{subject.kinmu_joukyou}</Grid>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>心理的な負担の状況</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}></Grid>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>A.ストレスの要因:</Grid>
            <Tooltip placement="left-start" title="低いほど高ストレス">
              <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{detail && `${detail.score.A} 点`}</Grid>
            </Tooltip>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>B.心身の自覚症状:</Grid>
            <Tooltip placement="left-start" title="低いほど高ストレス">
              <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{detail && `${detail.score.B} 点`}</Grid>
            </Tooltip>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>C.周囲の支援:</Grid>
            <Tooltip placement="left-start" title="低いほど高ストレス">
              <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{detail && `${detail.score.C} 点`}</Grid>
            </Tooltip>

          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}

export const ReportInfo = (props) => {
  const classes = useStyles();
  const report = props.report;

  return (
    <>
      <Box className={classes.doc_section_box}>
        <Box className={classes.doc_section_header_box} color="primary.contrastText" bgcolor="primary.main" p={2}>
          <Typography>面接指導結果報告書</Typography>
        </Box>
          <Box className={classes.doc_section_body_box}>
          <Grid container className={'doc_fields_grid_container'} spacing={2}>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>勤務の状況(労働時間、労働時間以外の要因):</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{report && report.kinmu_joukyou}</Grid>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>その他の心身の状況:</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{report && report.sonota_shinshin}</Grid>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>所見内容:</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{report && report.sonota_shinshin_text}</Grid>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>本人への指導区分:</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{report && report.shidou_kubun.join('、')}</Grid>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>再面接時期:</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{report && report.saimensetsu_jiki}</Grid>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>その他特記事項:</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{report && report.sonota_tokki}</Grid>
          </Grid>
        </Box>
      </Box>

      <Box className={classes.doc_section_box}>
        <Box className={classes.doc_section_header_box} color="primary.contrastText" bgcolor="primary.main" p={2}>
          <Typography>就業上の措置に係る意見書</Typography>
        </Box>
        <Box className={classes.doc_section_body_box}>
          <Grid container className={'doc_fields_grid_container'} spacing={2}>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>就業区分:</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{report && report.shuugyou_kubun}</Grid>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>就業上の措置 - 労働時間の短縮:</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{report && report.sochi_jikan.join('、')}</Grid>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>時間外労働の制限:</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{report && report.sochi_jikan_jikangai}</Grid>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>就業時間を制限:</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{report && report.sochi_jikan_shuugyou}</Grid>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>就業上の措置 - 労働時間以外の項目:</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{report && report.sochi_jikan_igai.join('、')}</Grid>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>具体的な措置の内容:</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{report && report.sochi_jikan_igai_text}</Grid>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>措置期間:</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{report && report.sochi_kikan}</Grid>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>職場環境の改善に関する意見:</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{report && report.kankyou_iken}</Grid>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>医療機関への受診配慮等:</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{report && report.jushin_hairyo}</Grid>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>その他 (連絡事項等):</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{report && report.sonota_renraku}</Grid>
          </Grid>
        </Box>
      </Box>

      <Box className={classes.doc_section_box}>
        <Box className={classes.doc_section_header_box} color="primary.contrastText" bgcolor="primary.main" p={2}>
          <Typography>報告書情報</Typography>
        </Box>
        <Box className={classes.doc_section_body_box}>
          <Grid container className={'doc_fields_grid_container'} spacing={2}>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>医師の所属先:</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{report && report.ishi_shozoku}</Grid>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>医師氏名:</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{report && report.ishi_name}</Grid>
            <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>実施年月日:</Grid>
            <Grid item className={classes.doc_fields_grid_item_value} xs={9}>{report && TimestampFormat(report.jisshi_date)}</Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

const ReportForm = (props) => {
  const classes = props.classes;
  const report = props.report;
  const onSubmit = props.onSubmit;
  const { register, control, handleSubmit, errors } = useForm();
  const [checkedItems, setCheckedItems] = useState(
    {
      shidou_kubun_0: report.shidou_kubun.includes('0. 措置不要'),
      shidou_kubun_1: report.shidou_kubun.includes('1. 要保健指導'),
      shidou_kubun_2: report.shidou_kubun.includes('2. 要経過観察'),
      shidou_kubun_3: report.shidou_kubun.includes('3. 要再面接'),
      shidou_kubun_4: report.shidou_kubun.includes('4. 現病治療継続 又は 医療機関紹介'),
      sochi_jikan_0: report.sochi_jikan.includes('0. 特に指示なし'),
      sochi_jikan_1: report.sochi_jikan.includes('1. 時間外労働の制限'),
      sochi_jikan_2: report.sochi_jikan.includes('2. 時間外労働の禁止'),
      sochi_jikan_3: report.sochi_jikan.includes('3. 就業時間を制限'),
      sochi_jikan_4: report.sochi_jikan.includes('4. 変形労働時間制または裁量労働制の対象からの除外'),
      sochi_jikan_5: report.sochi_jikan.includes('5. 就業の禁止(休暇・休養の指示)'),
      sochi_jikan_6: report.sochi_jikan.includes('6. その他'),
      sochi_jikan_igai_a: report.sochi_jikan_igai.includes('a. 就業場所の変更'),
      sochi_jikan_igai_b: report.sochi_jikan_igai.includes('b. 作業の転換'),
      sochi_jikan_igai_c: report.sochi_jikan_igai.includes('c. 深夜業の回数の減少'),
      sochi_jikan_igai_d: report.sochi_jikan_igai.includes('d. 昼間勤務への転換'),
      sochi_jikan_igai_e: report.sochi_jikan_igai.includes('e. その他'),
    }
  );

  const handleChange = (e) => {
    setCheckedItems({ ...checkedItems, [e.target.id]: e.target.checked });
    console.log(e.target);
    console.log(checkedItems);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} id="reportForm">
        <Box className={classes.doc_section_box}>
          <Box className={classes.doc_section_header_box} color="primary.contrastText" bgcolor="primary.main" p={2} my={4}>
            <Typography>面接指導結果報告書</Typography>
          </Box>
          <Box className={classes.doc_section_body_box}>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <FormControl className={[classes.formControl].join(' ')} fullWidth>
                  <FormLabel className={[classes.fieldLabel, classes.textFieldLabel].join(' ')} component="legend">勤務の状況(労働時間、労働時間以外の要因)</FormLabel>
                  <Tooltip placement="top-start" title="人事・労務担当者からの情報収集や、労働者への聞き取りから判断して記載する。">
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="kinmu_joukyou"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="kinmu_joukyou"
                      multiline
                      rows="5"
                      autoComplete="off"
                      placeholder=""
                      inputRef={register({ validate: value => validateMaxByteLength(value, 10240, '文字数が多すぎます。') })}
                      defaultValue={report.kinmu_joukyou}
                    />
                  </Tooltip>
                  {errors.kinmu_joukyou && <FormHelperText error>{errors.kinmu_joukyou.message}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={[classes.formControl].join(' ')} component="fieldset" fullWidth>
                  <FormLabel className={[classes.fieldLabel].join(' ')} component="legend">その他の心身の状況</FormLabel>
                  <Tooltip placement="top-start" title="本人の様子ややりとりから判断してその他の心身の状況を評価する。">
                    <RadioGroup
                      aria-label="sonota_shinshin"
                      name="sonota_shinshin"
                      row
                      defaultValue={report.sonota_shinshin}
                    >
                      <FormControlLabel inputRef={register({ required: 'その他の心身の状況を選択してください。' })} value="0. 所見なし" control={<Radio />} label="0. 所見なし" />
                      <FormControlLabel inputRef={register({ required: 'その他の心身の状況を選択してください。' })} value="1. 所見あり" control={<Radio />} label="1. 所見あり" />
                    </RadioGroup>
                  </Tooltip>
                  <Tooltip placement="top-start" title="必要に応じて所見の具体的内容を記載する。">
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="sonota_shinshin_text"
                      label="所見内容"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="sonota_shinshin_text"
                      autoComplete="off"
                      placeholder=""
                      inputRef={register({ validate: value => validateMaxByteLength(value, 10240, '文字数が多すぎます。') })}
                      defaultValue={report.sonota_shinshin_text}
                    />
                  </Tooltip>
                  {errors.sonota_shinshin && <FormHelperText error>{errors.sonota_shinshin.message}</FormHelperText>}
                  {errors.sonota_shinshin_text && <FormHelperText error>{errors.sonota_shinshin_text.message}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={[classes.formControl].join(' ')} component="fieldset">
                  <FormLabel className={[classes.fieldLabel].join(' ')} component="legend">本人への指導区分　※複数選択可</FormLabel>
                  <Tooltip placement="top-start" title="総合的に評価し、指導区分として0(措置不要)~4(現病治療継続)の中から該当するものを選択する。">
                    <FormGroup
                      aria-label="shidou_kubun"
                      name="shidou_kubun"
                      row
                    >
                      <ControlledCheckbox label="0. 措置不要" value="0. 措置不要" name="shidou_kubun" id="shidou_kubun_0" checkedItems={checkedItems} register={register} registerParam={{ required: '本人への指導区分を選択してください。' }} onChangeHandler={handleChange} />
                      <ControlledCheckbox label="1. 要保健指導" value="1. 要保健指導" name="shidou_kubun" id="shidou_kubun_1" checkedItems={checkedItems} register={register} registerParam={{ required: '本人への指導区分を選択してください。' }} onChangeHandler={handleChange} />
                      <ControlledCheckbox label="2. 要経過観察" value="2. 要経過観察" name="shidou_kubun" id="shidou_kubun_2" checkedItems={checkedItems} register={register} registerParam={{ required: '本人への指導区分を選択してください。' }} onChangeHandler={handleChange} />
                      <ControlledCheckbox label="3. 要再面接" value="3. 要再面接" name="shidou_kubun" id="shidou_kubun_3" checkedItems={checkedItems} register={register} registerParam={{ required: '本人への指導区分を選択してください。' }} onChangeHandler={handleChange} />
                      <ControlledCheckbox label="4. 現病治療継続 又は 医療機関紹介" value="4. 現病治療継続 又は 医療機関紹介" checkedItems={checkedItems} name="shidou_kubun" id="shidou_kubun_4" register={register} registerParam={{ required: '本人への指導区分を選択してください。' }} onChangeHandler={handleChange} />
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="saimensetsu_jiki"
                        label="再面接時期"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="saimensetsu_jiki"
                        autoComplete="off"
                        placeholder=""
                        inputRef={register({ validate: value => validateMaxByteLength(value, 10240, '文字数が多すぎます。') })}
                        defaultValue={report.saimensetsu_jiki}
                      />
                      {errors.saimensetsu_jiki && <FormHelperText error>{errors.saimensetsu_jiki.message}</FormHelperText>}
                      {errors.shidou_kubun && <FormHelperText error>{errors.shidou_kubun.message}</FormHelperText>}
                    </FormGroup>
                  </Tooltip>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={[classes.formControl].join(' ')} fullWidth>
                  <FormLabel className={[classes.fieldLabel, classes.textFieldLabel].join(' ')} component="legend">その他特記事項</FormLabel>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="sonota_tokki"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="sonota_tokki"
                    multiline
                    rows="5"
                    autoComplete="off"
                    placeholder=""
                    inputRef={register({ validate: value => validateMaxByteLength(value, 10240, '文字数が多すぎます。') })}
                    defaultValue={report.sonota_tokki}
                  />
                  {errors.sonota_tokki && <FormHelperText error>{errors.sonota_tokki.message}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box className={classes.doc_section_box}>
          <Box className={classes.doc_section_header_box} color="primary.contrastText" bgcolor="primary.main" p={2}>
            <Typography>就業上の措置に係る意見書</Typography>
          </Box>

          <Box className={classes.doc_section_body_box}>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <FormControl className={[classes.formControl].join(' ')} component="fieldset">
                  <FormLabel className={[classes.fieldLabel].join(' ')} component="legend">就業区分</FormLabel>
                  <Tooltip placement="top-start" title="「就業区分」として、0(通常勤務)~2(要休業)の中から該当するものを選択する。">
                    <RadioGroup
                      aria-label="shuugyou_kubun"
                      name="shuugyou_kubun"
                      row
                      defaultValue={report.shuugyou_kubun}
                    >
                      <FormControlLabel inputRef={register({ required: '就業区分を選択してください。' })} value="0. 通常勤務" control={<Radio />} label="0. 通常勤務" />
                      <FormControlLabel inputRef={register({ required: '就業区分を選択してください。' })} value="1. 就業制限・配慮" control={<Radio />} label="1. 就業制限・配慮" />
                      <FormControlLabel inputRef={register({ required: '就業区分を選択してください。' })} value="2. 要休業" control={<Radio />} label="2. 要休業" />
                    </RadioGroup>
                  </Tooltip>
                  {errors.shuugyou_kubun && <FormHelperText error>{errors.shuugyou_kubun.message}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item container direction="column" spacing={4}>
                <Grid item >
                  <FormControl className={[classes.formControl].join(' ')} component="fieldset">
                    <FormLabel className={[classes.fieldLabel].join(' ')} component="legend">就業上の措置 - 労働時間の短縮</FormLabel>
                    <Tooltip placement="top-start" title="具体的な就業上の措置については、該当するものを選択し、具体的な措置の内容についても記載する。なお、面接指導の時点では、具体的な措置の選択や内容まで判断がつかない場合には、考えられる措置(複数でも可)を選択し、具体的な内容の記載については、記載困難な場合は空欄とする。">
                      <FormGroup
                        aria-label="sochi_jikan"
                        name="sochi_jikan"
                      >
                        <ControlledCheckbox label="0. 特に指示なし" value="0. 特に指示なし" name="sochi_jikan" id="sochi_jikan_0" checkedItems={checkedItems} register={register} registerParam={{}} onChangeHandler={handleChange} />
                        <ControlledCheckbox label="1. 時間外労働の制限" value="1. 時間外労働の制限" name="sochi_jikan" id="sochi_jikan_1" checkedItems={checkedItems} register={register} registerParam={{}} onChangeHandler={handleChange} />
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="sochi_jikan_jikangai"
                          label=""
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="sochi_jikan_jikangai"
                          autoComplete="off"
                          placeholder="　時間/月まで"
                          inputRef={register({ validate: value => validateMaxByteLength(value, 10240, '文字数が多すぎます。') })}
                          defaultValue={report.sochi_jikan_jikangai}
                        />
                        {errors.sochi_jikan_jikangai && <FormHelperText error>{errors.sochi_jikan_jikangai.message}</FormHelperText>}
                        <ControlledCheckbox label="2. 時間外労働の禁止" value="2. 時間外労働の禁止" name="sochi_jikan" id="sochi_jikan_2" checkedItems={checkedItems} register={register} registerParam={{}} onChangeHandler={handleChange} />
                        <ControlledCheckbox label="3. 就業時間を制限" value="3. 就業時間を制限" name="sochi_jikan" id="sochi_jikan_3" checkedItems={checkedItems} register={register} registerParam={{}} onChangeHandler={handleChange} />
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="sochi_jikan_shuugyou"
                          label=""
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="sochi_jikan_shuugyou"
                          autoComplete="off"
                          placeholder="　時　分　〜　時　分まで"
                          inputRef={register({ validate: value => validateMaxByteLength(value, 10240, '文字数が多すぎます。') })}
                          defaultValue={report.sochi_jikan_shuugyou}
                        />
                        {errors.sochi_jikan_shuugyou && <FormHelperText error>{errors.sochi_jikan_shuugyou.message}</FormHelperText>}
                        <ControlledCheckbox label="4. 変形労働時間制または裁量労働制の対象からの除外" value="4. 変形労働時間制または裁量労働制の対象からの除外" name="sochi_jikan" id="sochi_jikan_4" checkedItems={checkedItems} register={register} registerParam={{}} onChangeHandler={handleChange} />
                        <ControlledCheckbox label="5. 就業の禁止(休暇・休養の指示)" value="5. 就業の禁止(休暇・休養の指示)" name="sochi_jikan" id="sochi_jikan_5" checkedItems={checkedItems} register={register} registerParam={{}} onChangeHandler={handleChange} />
                        <ControlledCheckbox label="6. その他" value="6. その他" name="sochi_jikan" id="sochi_jikan_6" checkedItems={checkedItems} register={register} registerParam={{}} onChangeHandler={handleChange} />
                        <Tooltip placement="top-start" title="「6.その他」の具体的な記載例としては、「休憩時間の確保」、「代休の取得」、「休日出勤の減少」、「夜勤明けは休日とする」、「夜勤中に仮眠が取れるようにする」などが考えられる。">
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="sochi_jikan_sonota"
                            label=""
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="sochi_jikan_sonota"
                            autoComplete="off"
                            placeholder=""
                            inputRef={register({ validate: value => validateMaxByteLength(value, 10240, '文字数が多すぎます。') })}
                            defaultValue={report.sochi_jikan_sonota}
                          />
                        </Tooltip>
                        {errors.sochi_jikan_sonota && <FormHelperText error>{errors.sochi_jikan_sonota.message}</FormHelperText>}
                      </FormGroup>
                    </Tooltip>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl className={[classes.formControl].join(' ')} component="fieldset">
                    <FormLabel className={[classes.fieldLabel].join(' ')} component="legend">就業上の措置 - 労働時間以外の項目</FormLabel>
                    <Tooltip placement="top-start" title="具体的な就業上の措置については、該当するものを選択し、具体的な措置の内容についても記載する。なお、面接指導の時点では、具体的な措置の選択や内容まで判断がつかない場合には、考えられる措置(複数でも可)を選択し、具体的な内容の記載については、記載困難な場合は空欄とする。">
                      <FormGroup
                        aria-label="sochi_jikan_igai"
                        name="sochi_jikan_igai"
                        row
                      >
                        <ControlledCheckbox label="a. 就業場所の変更" value="a. 就業場所の変更" name="sochi_jikan_igai" id="sochi_jikan_igai_a" checkedItems={checkedItems} register={register} registerParam={{}} onChangeHandler={handleChange} />
                        <ControlledCheckbox label="b. 作業の転換" value="b. 作業の転換" name="sochi_jikan_igai" id="sochi_jikan_igai_b" checkedItems={checkedItems} register={register} registerParam={{}} onChangeHandler={handleChange} />
                        <ControlledCheckbox label="c. 深夜業の回数の減少" value="c. 深夜業の回数の減少" name="sochi_jikan_igai" id="sochi_jikan_igai_c" checkedItems={checkedItems} register={register} registerParam={{}} onChangeHandler={handleChange} />
                        <ControlledCheckbox label="d. 昼間勤務への転換" value="d. 昼間勤務への転換" name="sochi_jikan_igai" id="sochi_jikan_igai_d" checkedItems={checkedItems} register={register} registerParam={{}} onChangeHandler={handleChange} />
                        <ControlledCheckbox label="e. その他" value="e. その他" name="sochi_jikan_igai" id="sochi_jikan_igai_e" checkedItems={checkedItems} register={register} registerParam={{}} onChangeHandler={handleChange} />
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="sochi_jikan_igai_text"
                          label="具体的な措置の内容"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="sochi_jikan_igai_text"
                          multiline
                          rows="3"
                          autoComplete="off"
                          placeholder=""
                          inputRef={register({ validate: value => validateMaxByteLength(value, 10240, '文字数が多すぎます。') })}
                          defaultValue={report.sochi_jikan_igai_text}
                        />
                        {errors.sochi_jikan_igai_text && <FormHelperText error>{errors.sochi_jikan_igai_text.message}</FormHelperText>}
                      </FormGroup>
                    </Tooltip>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item>
                <FormControl className={[classes.formControl].join(' ')} fullWidth>
                  <FormLabel className={[classes.fieldLabel, classes.textFieldLabel].join(' ')} component="legend">措置期間</FormLabel>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="sochi_kikan"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="sochi_kikan"
                    autoComplete="off"
                    placeholder="　日・週・月　又は　　年　月　日〜　年　月　日"
                    inputRef={register({ validate: value => validateMaxByteLength(value, 10240, '文字数が多すぎます。') })}
                    defaultValue={report.sochi_kikan}
                  />
                  {errors.sochi_kikan && <FormHelperText error>{errors.sochi_kikan.message}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={[classes.formControl].join(' ')} fullWidth>
                  <FormLabel className={[classes.fieldLabel, classes.textFieldLabel].join(' ')} component="legend">職場環境の改善に関する意見</FormLabel>
                  <Tooltip placement="top-start" title="職場環境(周囲のサポートを含む。)に問題がある場合は、必要に応じて改善のための意見を記載する。">
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="kankyou_iken"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="kankyou_iken"
                      multiline
                      rows="5"
                      autoComplete="off"
                      placeholder=""
                      inputRef={register({ validate: value => validateMaxByteLength(value, 10240, '文字数が多すぎます。') })}
                      defaultValue={report.kankyou_iken}
                    />
                  </Tooltip>
                  {errors.kankyou_iken && <FormHelperText error>{errors.kankyou_iken.message}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={[classes.formControl].join(' ')} fullWidth>
                  <FormLabel className={[classes.fieldLabel, classes.textFieldLabel].join(' ')} component="legend">医療機関への受診配慮等</FormLabel>
                  <Tooltip placement="top-start" title="医療機関への受診が必要な場合は、必要に応じて配慮事項を記載する。">
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="jushin_hairyo"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="jushin_hairyo"
                      multiline
                      rows="5"
                      autoComplete="off"
                      placeholder=""
                      inputRef={register({ validate: value => validateMaxByteLength(value, 10240, '文字数が多すぎます。') })}
                      defaultValue={report.jushin_hairyo}
                    />
                  </Tooltip>
                  {errors.jushin_hairyo && <FormHelperText error>{errors.jushin_hairyo.message}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={[classes.formControl].join(' ')} fullWidth>
                  <FormLabel className={[classes.fieldLabel, classes.textFieldLabel].join(' ')} component="legend">その他 (連絡事項等)</FormLabel>
                  <Tooltip placement="top-start" title="その他、事業者に対して伝えておくべき事項があれば、必要に応じて記載する。">
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="sonota_renraku"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="sonota_renraku"
                      multiline
                      rows="5"
                      autoComplete="off"
                      placeholder=""
                      inputRef={register({ validate: value => validateMaxByteLength(value, 10240, '文字数が多すぎます。') })}
                      defaultValue={report.sonota_renraku}
                    />
                  </Tooltip>
                  {errors.sonota_renraku && <FormHelperText error>{errors.sonota_renraku.message}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box className={classes.doc_section_box}>
          <Box className={classes.doc_section_header_box} color="primary.contrastText" bgcolor="primary.main" p={2}>
            <Typography>報告書情報</Typography>
          </Box>

          <Box className={classes.doc_section_body_box}>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <FormControl className={[classes.formControl].join(' ')} fullWidth>
                  <FormLabel className={[classes.fieldLabel, classes.textFieldLabel].join(' ')} component="legend">医師の所属先</FormLabel>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="ishi_shozoku"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="ishi_shozoku"
                    autoComplete="off"
                    placeholder=""
                    inputRef={register({
                      required: '医師の所属先を入力してください。',
                      validate: value => validateMaxByteLength(value, 10240, '文字数が多すぎます。'),
                    })}
                    defaultValue={report.ishi_shozoku}
                  />
                  {errors.ishi_shozoku && <FormHelperText error>{errors.ishi_shozoku.message}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={[classes.formControl].join(' ')}>
                  <FormLabel className={[classes.fieldLabel, classes.textFieldLabel].join(' ')} component="legend">医師氏名</FormLabel>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="ishi_name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="ishi_name"
                    autoComplete="off"
                    placeholder=""
                    inputRef={register({
                      required: '医師氏名を入力してください。',
                      validate: value => validateMaxByteLength(value, 10240, '文字数が多すぎます。'),
                    })}
                    defaultValue={report.ishi_name}
                  />
                  {errors.ishi_name && <FormHelperText error>{errors.ishi_name.message}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={[classes.formControl].join(' ')}>
                  <FormLabel className={[classes.fieldLabel].join(' ')} component="legend">実施年月日</FormLabel>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Controller
                      as={<KeyboardDatePicker />}
                      name="jisshi_date"
                      control={control}
                      disableToolbar
                      autoOk
                      rules={{ required: '実施年月日を入力してください。' }}
                      invalidDateMessage="実施年月日の日付形式が不正です。"
                      variant="inline"
                      inputVariant="outlined"
                      format="yyyy/MM/dd"
                      disableFuture="true"
                      margin="normal"
                      id="jisshi_date"
                      KeyboardButtonProps={{
                        'aria-label': 'jisshi_date',
                      }}
                      defaultValue={new Date(report.jisshi_date.seconds * 1000)}
                    />
                  </MuiPickersUtilsProvider>
                  {errors.jisshi_date && <FormHelperText error>{errors.jisshi_date.message}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </form>
    </>
  );
}

const ControlledCheckbox = ({ label, value, name, id, checkedItems, register, registerParam, onChangeHandler }) => {

  return (
    <FormControlLabel
      label={label}
      value={value}
      name={name}
      control={<Checkbox inputProps={{ id: id }} />}
      checked={checkedItems[id]}
      inputRef={register(registerParam)}
      onChange={onChangeHandler}
    />
  );
}

const reportDefault = {
  kinmu_joukyou: '',
  sonota_shinshin: '',
  sonota_shinshin_text: '',
  shidou_kubun: [],
  saimensetsu_jiki: '',
  sonota_tokki: '',
  shuugyou_kubun: '',
  sochi_jikan: [],
  sochi_jikan_jikangai: '　時間/月まで',
  sochi_jikan_shuugyou: '　時　分　〜　時　分まで',
  sochi_jikan_sonota: '',
  sochi_jikan_igai: [],
  sochi_jikan_igai_text: '',
  sochi_kikan: '　日・週・月　又は　　年　月　日〜　年　月　日',
  kankyou_iken: '',
  jushin_hairyo: '',
  sonota_renraku: '',
  ishi_name: '',
  ishi_shozoku: '',
  jisshi_date: { seconds: (new Date()).getTime() / 1000.0 },
};

const validateMaxByteLength = (value, maxLength, message) => {
  // registerで指定するmaxLengthでは文字数でのバリデーションになるため
  // バイト数でのバリデーションを行う
  return (encodeURIComponent(value).replace(/%../g, 'x').length <= maxLength) ? true : message;
}
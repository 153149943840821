import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';

import { useMessage } from '../hooks/Message';

const useStyles = makeStyles(theme => ({
  root: {
  },
}));

export const Interview = () => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const { id } = useParams();
  const { qinfo } = useMessage('interviews', id);

  const onSubmit = async (data) => {
    const result = {
      interview_request: data.answer === "true" ? true : false,
      share_request: data.share === "true" ? true : false,
    }
    console.log('onSubmit', id, result);

    try {
      await fetch(`${process.env.REACT_APP_APIHOST}/interviews/${id}/answer`, {
        method: 'POST',
        body: JSON.stringify(result),
        headers: { 'Content-Type': 'application/json' },
      });
      history.push(`/interview/${id}/submit`);
    } catch (error) {
      // TODO: APIエラー処理
      console.log(error);
    }
  }

  // アンケート情報取得中
  if (!qinfo) {
    return <LinearProgress />
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container fixed>
        <Box color="primary.contrastText" bgcolor="primary.main" p={4} mt={2} mb={5}>
          <Typography variant="h3">ストレスチェック実施プログラム</Typography>
        </Box>
        <Box mt={1} mb={2}>
          <Typography >あなたは職場におけるストレスが高い数値を示しています。</Typography>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={2} borderBottom={1}>
            <Typography>事業者へ医師面談の申し込みを行いますか</Typography>
            <FormControl component="fieldset">
              {errors["answer"] && <FormHelperText error>回答を入力してください。</FormHelperText>}
              <RadioGroup name="answer" row>
                <FormControlLabel inputRef={register({ required: true })} value="true" control={<Radio color="primary" />} label="はい" />
                <FormControlLabel inputRef={register({ required: true })} value="false" control={<Radio color="primary" />} label="いいえ" />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box mt={2} borderBottom={1}>
            <Typography>あなたの検査結果を事業者や面談を担当する医師へ開示しますか？</Typography>
            <FormControl component="fieldset">
              {errors["share"] && <FormHelperText error>回答を入力してください。</FormHelperText>}
              <RadioGroup name="share" row>
                <FormControlLabel inputRef={register({ required: true })} value="true" control={<Radio color="primary" />} label="はい" />
                <FormControlLabel inputRef={register({ required: true })} value="false" control={<Radio color="primary" />} label="いいえ" />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box mt={10} mb={20}>
            <Box color="info.contrastText" bgcolor="info.main" mb={2}>
              <Typography>入力した内容に間違いがないかどうか確認して、送信ボタンを押してください。</Typography>
            </Box>
            <Button type="submit" variant="contained" color="primary" >送信</Button>
          </Box>
        </form>
      </Container >
    </div>
  );
};

export const InterviewSubmit = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container fixed>
        <Box color="primary.contrastText" bgcolor="primary.main" p={4} mt={2} mb={5}>
          <Typography variant="h3">ストレスチェック実施プログラム</Typography>
        </Box>
        <Box mt={1} mb={2}>
          <Typography >入力を受け付けました。</Typography>
          <Typography >別途事業者よりお知らせします。</Typography>
        </Box>
      </Container >
    </div>
  );
};

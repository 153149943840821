
import firebase from 'firebase/app';
import { useState, useEffect } from 'react';

export const useDetail = (docid, sid) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [detail, setDetail] = useState(null);

  useEffect(
    () => {
      if (!docid || !sid) {
        return;
      }
      const unsbscribe = firebase.firestore().collection('projects').doc(docid)
        .collection('details').doc(sid).onSnapshot(
          snapshot => {
            if (snapshot.exists) {
              setLoading(false);
              setDetail(snapshot.data());
            } else {
              setLoading(true);
              setDetail(null);
            }
          },
          err => {
            setError(err);
          });

      return () => unsbscribe();
    }, [docid, sid]
  );

  return { detail, error, loading };
}
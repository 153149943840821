import React from 'react';
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';

import { QuestionMaster, StressProfileMaster } from '../components/Master';
import { useMessage } from '../hooks/Message';

const useStyles = makeStyles(theme => ({
  root: {
  },
}));

export const Questionnaire = () => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const { id } = useParams();
  const { qinfo } = useMessage('questionnaires', id);

  const onSubmit = async (data) => {
    const {profiles, score} = calcProfiles(StressProfileMaster, data, qinfo.sex);
    const result = {
      id: id,
      answer: data,
      score: score,
      stress: highStress(score),
      stress_profile: profiles,
    }

    try {
      await fetch(`${process.env.REACT_APP_APIHOST}/questionnaires/${id}/answer`, {
        method: 'POST',
        body: JSON.stringify(result),
        headers: { 'Content-Type': 'application/json' },
      });
      history.push(`/questionnaire/${id}/submit`);
    } catch (error) {
      // TODO: APIエラー処理
      console.log(error);
    }
  }

  // アンケート情報取得中
  if (!qinfo) {
    return <LinearProgress />
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container fixed>
        <Box color="primary.contrastText" bgcolor="primary.main" p={4} mt={2} mb={5}>
          <Typography variant="h3">ストレスチェック実施プログラム</Typography>
        </Box>
        <Box mt={1} mb={2}>
          <Typography >職業性ストレス簡易調査票を用いて、あなたの職場におけるストレスレベルを測定します。<br />質問は全部で57問です。(所要時間5分間)</Typography>
        </Box>
        <Box mt={1}>
          <ol>
            <li>この検査は、労働安全衛生法に基づくものです。</li>
            <li>検査結果は、あなたの同意なく会社側に伝わることはありません。</li>
            <li>検査の実施責任者は、{qinfo ? qinfo.username : '??'} です。</li>
          </ol>
        </Box>
        <Box mt={5}>
          <Typography variant="h5">職業性ストレス簡易調査票(57 項目)</Typography>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          {QuestionMaster.map((section, si) => (
            <Box key={si} mt={2}>
              <Box color="primary.contrastText" bgcolor="primary.main" p={2}>
                <Typography>{section.id+ '. ' + section.text}</Typography>
              </Box>
              {section.group.map((g, gid) => (
                <Box key={gid} mt={2}>
                  {g.text && <Box color="info.contrastText" bgcolor="info.main" p={2}><Typography>{g.text}</Typography></Box>}
                  {g.questions.map((question, qi) => (
                    <Box key={qi} mt={2} borderBottom={1}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">{`${qi + 1}. ${question.text}`}</FormLabel>
                        {errors[question.id] && <FormHelperText error>回答を入力して下さい</FormHelperText>}
                        <RadioGroup defaultValue={qinfo.answer && qinfo.answer[question.id]} name={question.id} row>
                          {section.options.map((a, aid) => (
                            <FormControlLabel inputRef={register({ required: true })} key={aid} value={String(aid)} control={<Radio color="primary" />} label={a} />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          ))}

          <Box mt={10} mb={20}>
            <Box color="info.contrastText" bgcolor="info.main" mb={2}>
              <Typography>これで質問は終わりです。入力した内容に間違いがないかどうか確認して、送信ボタンを押してください。</Typography>
            </Box>
            <Button type="submit" variant="contained" color="primary" >送信</Button>
          </Box>
        </form>
      </Container >
    </div>
  );
};

export const QuestionnaireSubmit = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container fixed>
        <Box color="primary.contrastText" bgcolor="primary.main" p={4} mt={2} mb={5}>
          <Typography variant="h3">ストレスチェック実施プログラム</Typography>
        </Box>
        <Box mt={1} mb={2}>
          <Typography >お疲れ様でした。入力した内容を受け付けました。</Typography>
          <Typography >ストレスチェックの結果は、別途担当者よりお知らせします。</Typography>
        </Box>
      </Container >
    </div>
  );
};

// [労働安全衛生法に基づくストレスチェック制度実施マニュアル（令和元年7月改訂）](https://www.mhlw.go.jp/content/000533925.pdf)
// 43ページの高ストレス者評価基準その1
// const calcScore = (master, answer) => {
//   let result = {};
//   master.forEach((section) => {
//     let score = 0;
//     section.group.forEach((g, gid) => {
//       g.questions.forEach((question) => {
//         let a = Number(answer[question.id]);
//         if (a < 0 || a > 3) {
//           return null;
//         }
//         // type:1 高ポイントほど高ストレス
//         // type:0 高ポイントほど低ストレス
//         if (question.type === 1) {
//           a = 3 - a;
//         }
//         score += a + 1;
//       });
//     });
//     result[section.id] = score;
//   });
//   return result;
// }
// 
// const validateStress = (score) => {
//   if (score['B'] >= 77) {
//     return true;
//   }
//   if (score['A'] + score['C'] >= 76 && score['B'] >= 63) {
//     return true;
//   }
//   return false;
// }

// [労働安全衛生法に基づくストレスチェック制度実施マニュアル（令和元年7月改訂）](https://www.mhlw.go.jp/content/000533925.pdf)
// 45ページ　高ストレス者評価基準その2
const highStress = (score) => {
  if (score['B'] <= 12) {
    return true;
  }
  if (score['A'] + score['C'] <= 26 && score['B'] <= 17) {
    return true;
  }
  return false;
};

const calcProfiles = (master, answer, sex = 'male') => {
  // 評価表の回答からストレスプロフィールを計算する
  // 
  // [労働安全衛生法に基づくストレスチェック制度実施マニュアル（令和元年7月改訂）](https://www.mhlw.go.jp/content/000533925.pdf)
  // 44ページ　素点換算表
  // 199ページ　素点換算手順

  // 回答値は0から3だがストレスプロフィールの計算では1から4とする
  let normalizedAnswer = {};
  Object.entries(answer).forEach(([k, v]) => {
    normalizedAnswer[k] = parseInt(v) + 1;
  });

  const profiles = {};
  const score = {A: 0, B: 0, C: 0};
  master.forEach(m => {
    m.profiles.forEach(p => {
      // マスタから計算式を取得する
      const summarizeAnswerDelegate = p.summarizeAnswerDelegate;
      // マスタから性別別の素点換算表を取得する
      const rankTable = p.rankTable[sex];

      // 各尺度ごとの評価点を算出する
      let point = 0;
      if (rankTable) {
        point = calcPoint(normalizedAnswer, summarizeAnswerDelegate, rankTable);
      }

      // p.id: ストレスプロフィールの尺度ID（マスタのIDと紐づく）
      // point: 評価点（1~5で低いほど高ストレス）
      profiles[p.id] = point;
      score[m.id] += point;
    });
  });

  return {profiles, score};
}

const calcPoint = (answer, summarizeAnswerDelegate, rankTable) => {
  // 調査票回答値と素点換算表から各尺度ごとの評価点を算出する
  // answer: 調査票回答値（正規化済み）
  // summarizeAnswerDelegate: StressProfileMasterに設定してある計算式
  // rankTable: StressProfileMasterに設定してある素点換算表
  
  // 調査票の回答値を尺度単位で加算する
  const summarizedAnswer = summarizeAnswerDelegate(answer);

  // 素点換算表から評価点を決定する
  let point = 0;
  for (const [i, rankRange] of rankTable.entries()) {
    // 得点が含まれる範囲を特定しその配列インデックスを評価点とする
    if (rankRange.lower <= summarizedAnswer && summarizedAnswer <= rankRange.upper) {
      point = i;
      break;
    }
  }

  return point;
}
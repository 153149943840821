import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAk5uGBY4vWhOTugGky4r1zuavej0dkWs4",
  authDomain: "stcheck.firebaseapp.com",
  databaseURL: "https://stcheck.firebaseio.com",
  projectId: "stcheck",
  storageBucket: "stcheck.appspot.com",
  messagingSenderId: "628916868442",
  appId: "1:628916868442:web:4d80dd882325f90a0e8f63",
  measurementId: "G-5LLNC2VVQC"
};
firebase.initializeApp(firebaseConfig);
firebase.auth().languageCode = 'ja';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import { useParams } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import LinearProgress from '@material-ui/core/LinearProgress';
import HomeIcon from '@material-ui/icons/Home';

import { Main } from './Main';
import { ProjectListPaper, ProjectPaper } from '../components/Project';
import { SubjectListPaper } from '../components/Subject';
import { useProjectList, useProject } from '../hooks/Project';

const useStyles = makeStyles(theme => ({
  icon: {
    verticalAlign: 'middle',
  },
}));

export const ProjectListView = ({ user }) => {
  const classes = useStyles();
  const { projects } = useProjectList(user.email);

  if (!projects) {
    return <LinearProgress />;
  }
  return (
    <Main user={user} >
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary"><HomeIcon className={classes.icon} /> ホーム</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item xs={12}>
          <Typography>ストレスチェックを実施するためのプロジェクトは次の通りです。</Typography>
        </Grid>

        <Grid item xs={12}>
          <ProjectListPaper user={user} list={projects} />
        </Grid>

      </Grid>
    </Main >
  );
}

export const ProjectView = ({ user }) => {
  const { id } = useParams();
  const { project } = useProject(id);

  if (!project) {
    return <LinearProgress />;
  }

  return (
    <Main user={user} pid={id} project={project}>
      <Grid container spacing={3}>

        {/* <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Button
              aria-label="more-vert"
              onClick={() => history.push(`/project/${id}`)}
              startIcon={<FolderIcon />}
            >
              {project.name}
            </Button>
            <Typography>詳細</Typography>
          </Breadcrumbs>
        </Grid> */}

        <Grid item xs={12}>
          <Typography>ようこそ、「{project.name}」へ</Typography>
        </Grid>

        <Grid item xs={12}>
          <ProjectPaper user={user} pid={id} project={project} />
        </Grid>

        <Grid item xs={12}>
          <SubjectListPaper user={user} pid={id} project={project} />
        </Grid>

      </Grid>
    </Main >
  );
}
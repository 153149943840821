import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles(theme => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
}));

const Messagebar = (props) => {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    console.log('Messagebar.handleClose');
    if (reason === 'clickaway') {
      return;
    }
    props.onClose();
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={props.code !== ''}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={classes.error}
        message={
          <span id="client-snackbar" className={classes.message}>
            <ErrorIcon className={classes.iconVariant} />
            {errorMessage(props.code)}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}

const errorMessage = (code) => {
  const errorList = {
    'auth/invalid-email': 'Emailアドレスの形式が正しくありあません',
    'auth/wrong-password': 'Emailアドレスまたはパスワードが正しくありません',
    'auth/user-not-found': 'Emailアドレスまたはパスワードが正しくありません',
    'auth/user-disabled': 'このユーザは利用できません',
    'auth/email-already-in-use': 'このEmailアドレスはすでに使用済みです',
    'default': '不明なエラーが発生しました',
  }

  let msg = errorList[code];
  if (!msg) {
    msg = errorList['default'] + '(' + code + ')';
  }
  return msg;
}

export default Messagebar;
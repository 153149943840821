
import firebase from 'firebase/app';
import { useState, useEffect } from 'react';

export const useSubjectList = (email, docid, project) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [subjectList, setSubjectList] = useState([]);

  useEffect(
    () => {
      if (!project) { return; }
      const role = project.roles[email];
      let query = firebase.firestore().collection('projects').doc(docid).collection('subjects');

      if (role !== 'admin') {
        query = query.where('interview_request', '==', true);
      }
      const unsbscribe = query.onSnapshot(
        snapshot => {
          setLoading(false);
          setSubjectList(snapshot.docs);
        },
        err => {
          setError(err);
        });

      return () => unsbscribe();
    }, [email, docid, project]
  );

  return { subjectList, error, loading };
}

export const useSubject = (docid, sid) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [subject, setSubject] = useState(null);

  useEffect(
    () => {
      if (!docid || !sid) {
        return;
      }
      const unsbscribe = firebase.firestore().collection('projects').doc(docid)
        .collection('subjects').doc(sid).onSnapshot(
          snapshot => {
            if (snapshot.exists) {
              setLoading(false);
              setSubject(snapshot.data());
            } else {
              setLoading(true);
              setSubject(null);
            }
          },
          err => {
            setError(err);
          });

      return () => unsbscribe();
    }, [docid, sid]
  );

  return { subject, error, loading };
}

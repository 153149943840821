import { createMuiTheme } from '@material-ui/core/styles';

// 公式カラーパレットエディタ
// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=FFCDD2&secondary.color=B3E5FC

const defaultTheme = createMuiTheme({
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#fafafa',
    },
    primary: {
      main: '#ffcdd2',
    },
    secondary: {
      main: '#b3e5fc',
    },
    error: {
      main: '#f44336',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  typography: {
    fontSize: 14, // フォントサイズを調整
    button: {
      textTransform: 'none', // ボタンの小文字を自動的に大文字にしない
    },
  },
  props: {
    MuiTextField: {
      variant: 'outlined', // TextFieldのディフォルトスタイル
    },
    MuiButton: {
      variant: 'contained', // Buttonのディフォルトスタイル
    },
    MuiCheckbox: {
      color: 'primary', // 色のディフォルトをprimaryへ統一
    },
    MuiRadio: {
      color: 'primary',// 色のディフォルトをprimaryへ統一
    },
    MuiSwitch: {
      color: 'primary',// 色のディフォルトをprimaryへ統一
    },
  },
});

// 全体的に詰めたい場合は次を参照
// https://material-ui.com/customization/density/

export default defaultTheme;
import React from 'react';
import { useParams } from 'react-router-dom'

import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

import { useProject } from '../hooks/Project';
import { useSubject } from '../hooks/Subject';
import { useDetail } from '../hooks/Detail';
import { useReportList } from '../hooks/Report';
import { SubjectPaper, SubjectStatusPaper } from '../components/Subject';
import { StressProfilePaper } from '../components/StressProfile';
import { ReportListPaper } from '../components/Report';
import { Main } from '../views/Main';

export const SubjectView = ({ user }) => {
  const { id, sid } = useParams();
  const { project } = useProject(id);
  const { subject } = useSubject(id, sid);
  const { detail } = useDetail(id, sid);
  const { reportList } = useReportList(id, sid);

  if (!subject) {
    return <LinearProgress />
  }

  return (
    <Main user={user} pid={id} project={project}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SubjectStatusPaper subject={subject} detail={detail} reports={reportList} />
        </Grid>

        <Grid item xs={12}>
          <SubjectPaper user={user} pid={id} project={project} sid={sid} subject={subject} detail={detail} />
        </Grid>

        <Grid item xs={12}>
          <StressProfilePaper user={user} pid={id} project={project} sid={sid} subject={subject} detail={detail} />
        </Grid>

        <Grid item xs={12}>
          <ReportListPaper pid={id} sid={sid} subject={subject} reports={reportList} />
        </Grid>

      </Grid>
    </Main >
  );
}

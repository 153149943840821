import React from 'react';
import { useParams } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import { useProject } from '../hooks/Project';
import { useSubject } from '../hooks/Subject';
import { useDetail } from '../hooks/Detail';
import { useReport } from '../hooks/Report';
import { ReportPaper, ReportCreatePaper, ReportUpdatePaper, ReportSubjectDetailPaper } from '../components/Report';
import { Main } from '../views/Main';

const useStyles = makeStyles(theme => ({
  root: {
  },
  button: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  title_box: {
    margin: theme.spacing(2, 0, 2),
  },
  doc_section_box: {
    margin: theme.spacing(4, 0, 4),
  },
  doc_section_header_box: {
    margin: theme.spacing(2, 0, 2),
  },
  action_button_box: {
    margin: theme.spacing(8, 0, 8),
  },
  formControl: {
    paddingLeft: theme.spacing(2),
  },
  fieldLabel: {
    fontWeight: "bold",
    marginLeft: theme.spacing(-2),
  },
  fieldContents: {
    paddingRight: theme.spacing(2),
  },
  textFieldLabel: {
    marginBottom: theme.spacing(2),
  },
  command: {
    padding: theme.spacing(1, 1, 1, 1),
  },
}));

export const ReportView = ({ user }) => {
  const classes = useStyles();
  const { pid, sid, rid } = useParams();
  const { project } = useProject(pid);
  const { subject } = useSubject(pid, sid);
  const { detail } = useDetail(pid, sid);
  const { report } = useReport(pid, sid, rid);

  if (!project || !subject || !detail || !report) {
    return <LinearProgress />
  }

  return (
    <Main user={user} pid={pid} project={project}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box className={classes.title_box}>
            <Typography>面接指導報告書</Typography>
          </Box>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <ReportSubjectDetailPaper subject={subject} detail={detail} />
        </Grid>
        <Grid item xs={12}>
          <ReportPaper pid={pid} sid={sid} subject={subject} detail={detail} rid={rid} report={report} />
        </Grid>
      </Grid>
    </Main>
  )
}

export const ReportCreateView = ({ user }) => {
  const classes = useStyles();
  const { pid, sid } = useParams();
  const { project } = useProject(pid);
  const { subject } = useSubject(pid, sid);
  const { detail } = useDetail(pid, sid);

  if (!subject) {
    return <LinearProgress />
  }
  // TODO: ツールチップで入力内容を表示
  // TODO: テキスト入力の最大文字数を制限
  return (
    <Main user={user} pid={pid} project={project}>
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <Box className={classes.title_box}>
            <Typography>面接指導報告書</Typography>
          </Box>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <ReportSubjectDetailPaper subject={subject} detail={detail} />
        </Grid>
        <Grid item xs={12}>
          <ReportCreatePaper pid={pid} sid={sid} subject={subject} detail={detail} />
        </Grid>
      </Grid >
    </Main>
  );
}

export const ReportUpdateView = ({ user }) => {
  const classes = useStyles();
  const { pid, sid, rid } = useParams();
  const { project } = useProject(pid);
  const { subject } = useSubject(pid, sid);
  const { detail } = useDetail(pid, sid);
  const { report } = useReport(pid, sid, rid);

  if (!report) {
    return <LinearProgress />
  }

  // TODO: ツールチップで入力内容を表示
  // TODO: テキスト入力の最大文字数を制限
  return (
    <Main user={user} pid={pid} project={project}>
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <Box className={classes.title_box}>
            <Typography>面接指導報告書</Typography>
          </Box>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <ReportSubjectDetailPaper subject={subject} detail={detail}/>
        </Grid>
        <Grid item xs={12}>
          <ReportUpdatePaper pid={pid} sid={sid} subject={subject} detail={detail} rid={rid} report={report} />
        </Grid>
      </Grid>
    </Main>
  );
}

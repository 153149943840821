import firebase from 'firebase/app';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';
import MailIcon from '@material-ui/icons/Mail';

import { RoleInfo } from './Master'

const useStyles = makeStyles(theme => ({
  command: {
    padding: theme.spacing(1, 1, 1, 1),
  },
  icon: {
    verticalAlign: 'middle',
  },
}));

export const RoleListPaper = ({ user, pid, project }) => {
  const classes = useStyles();
  const [add, setAdd] = React.useState(false);
  const [edit, setEdit] = React.useState(null);
  const [del, setDel] = React.useState(null);

  return (
    <Paper>
      <Box className={classes.command} display="flex" flexDirection="row-reverse">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => { setAdd(true) }}
          startIcon={<AddIcon />}
        >
          メンバーを追加
              </Button>
      </Box>
      {/* <AddMenu user={user} pid={id} project={project} /> */}
      <Table aria-label="a table">
        <TableHead>
          <TableRow>
            <TableCell>識別子(メールアドレス)</TableCell>
            <TableCell align="left">役割</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(project.roles).map((k, i) => (
            <TableRow key={k}>
              <TableCell align="left" ><MailIcon className={classes.icon} /> {k}</TableCell>
              <TableCell align="left">{RoleInfo[project.roles[k]].name}</TableCell>
              <TableCell align="right">
                <CellMenu id={`simple-menu-${i}`} onRole={() => setEdit(k)} onDelete={() => setDel(k)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <AddDialog user={user} pid={pid} project={project} open={add} onClose={() => setAdd(false)} />
      <EditRoleDialog user={user} pid={pid} project={project} edit={edit} onClose={() => setEdit(null)} />
      <DeleteDialog user={user} pid={pid} project={project} edit={del} onClose={() => setDel(null)} />
    </Paper>
  );
}

const CellMenu = ({ id, onRole, onDelete }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCommand = (cb) => {
    if (cb) {
      cb();
      setAnchorEl(null);
    }
  };

  return (
    <>
      <IconButton
        aria-label="more-vert"
        aria-controls={id}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={e => handleCommand(onRole)}>役割の変更</MenuItem>
        <MenuItem onClick={e => handleCommand(onDelete)}>メンバー削除</MenuItem>
      </Menu>
    </>
  );
}

const AddDialog = ({ user, pid, project, open, onClose }) => {
  const { register, handleSubmit, errors, control } = useForm();

  const onSubmit = async (data) => {
    if (user.email === data.email) {
      return;
    }
    try {
      await firebase.firestore().collection('projects').doc(pid).set({
        members: project.members.concat(data.email),
        roles: { [data.email]: data.role },
      }, { merge: true });
    } catch (error) {
      console.log(error);
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="form-dialog-title">メンバー追加</DialogTitle>
        <DialogContent>
          <DialogContentText>
            プロジェクトにメンバーを追加します。追加するメンバーのメールアドレスと役割を入力して、決定ボタンを押してください。
            </DialogContentText>
          <TextField
            type="email"
            name="email"
            label="識別子(メールアドレス)"
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={
              register({
                required: 'emailアドレスを入力してください。',
                validate: v => !project.members.includes(v) || 'すでに登録済みのメンバーです。',
              })
            }
            error={errors.email && true}
            helperText={errors.email && errors.email.message}
          />

          <Controller
            as={<TextField
              select
              label="役割"
              margin="normal"
              fullWidth
            >
              <MenuItem value="admin">{RoleInfo.admin.name}</MenuItem>
              <MenuItem value="operator">{RoleInfo.operator.name}</MenuItem>
              <MenuItem value="doctor">{RoleInfo.doctor.name}</MenuItem>
            </TextField>}
            name="role"
            control={control}
            onChange={([selected]) => {
              return { value: selected.target.value };
            }}
            defaultValue="admin"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">キャンセル</Button>
          <Button type="submit" color="primary">決定</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const EditRoleDialog = ({ user, pid, project, edit, onClose }) => {
  const { register, handleSubmit, errors, control } = useForm();

  const onSubmit = async (data) => {
    try {
      await firebase.firestore().collection('projects').doc(pid).set({
        roles: { [edit]: data.role },
      }, { merge: true });
    } catch (error) {
      console.log(error);
    }
    onClose();
  };

  return (
    <Dialog open={edit !== null} onClose={onClose} aria-labelledby="form-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="form-dialog-title">メンバー役割の変更</DialogTitle>
        {user.email === edit ? (
          <DialogContent>
            <DialogContentText>
              自分の役割は変更できません。
            </DialogContentText>
          </DialogContent>
        ) : (
            <DialogContent>
              <DialogContentText>
                プロジェクトのメンバー役割を変更します。役割を選んで、決定ボタンを押してください。
              </DialogContentText>
              <TextField
                disabled
                defaultValue={edit}
                type="email"
                name="email"
                label="識別子(メールアドレス)"
                margin="normal"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={
                  register({
                    required: 'emailアドレスを入力してください。',
                    validate: v => project.members.includes(v) || 'メンバーが登録されていません。',
                  })
                }
                error={errors.email && true}
                helperText={errors.email && errors.email.message}
              />

              <Controller
                as={<TextField
                  select
                  label="役割"
                  margin="normal"
                  fullWidth
                >
                  <MenuItem value="admin">{RoleInfo.admin.name}</MenuItem>
                  <MenuItem value="operator">{RoleInfo.operator.name}</MenuItem>
                  <MenuItem value="doctor">{RoleInfo.doctor.name}</MenuItem>
                </TextField>}
                name="role"
                control={control}
                onChange={([selected]) => {
                  return { value: selected.target.value };
                }}
                defaultValue={project.roles[edit]}
              />
            </DialogContent>
          )}
        <DialogActions>
          <Button onClick={onClose} color="primary">キャンセル</Button>
          {user.email !== edit && <Button type="submit" color="primary">決定</Button>}
        </DialogActions>
      </form>
    </Dialog>
  );
}

const DeleteDialog = ({ user, pid, project, edit, onClose }) => {
  const { register, handleSubmit, errors, control } = useForm();

  const onSubmit = async (data) => {

    let roles = Object.assign({}, project.roles);
    delete roles[edit];

    try {
      await firebase.firestore().collection('projects').doc(pid).update({
        members: project.members.filter(n => n !== edit),
        roles: roles,
      });
    } catch (error) {
      console.log(error);
    }
    onClose();
  };

  return (
    <Dialog open={edit !== null} onClose={onClose} aria-labelledby="form-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="form-dialog-title">メンバーの削除</DialogTitle>
        {user.email === edit ? (
          <DialogContent>
            <DialogContentText>
              自分はメンバーから削除できません。
            </DialogContentText>
          </DialogContent>
        ) : (
            <DialogContent>
              <DialogContentText>
                プロジェクトのメンバーを削除します。間違いがないかを確認して、決定ボタンを押してください。
              </DialogContentText>
              <TextField
                disabled
                defaultValue={edit}
                type="email"
                name="email"
                label="識別子(メールアドレス)"
                margin="normal"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={
                  register({
                    required: 'emailアドレスを入力してください。',
                    validate: v => project.members.includes(v) || 'メンバーが登録されていません。',
                  })
                }
                error={errors.email && true}
                helperText={errors.email && errors.email.message}
              />

              <Controller
                as={<TextField
                  disabled
                  select
                  label="役割"
                  margin="normal"
                  fullWidth
                >
                  <MenuItem value="admin">admin</MenuItem>
                  <MenuItem value="operator">operator</MenuItem>
                  <MenuItem value="doctor">doctor</MenuItem>
                </TextField>}
                name="role"
                control={control}
                onChange={([selected]) => {
                  return { value: selected.target.value };
                }}
                defaultValue={project.roles[edit]}
              />
            </DialogContent>
          )}
        <DialogActions>
          <Button onClick={onClose} color="primary">キャンセル</Button>
          {user.email !== edit && <Button type="submit" color="primary">決定</Button>}
        </DialogActions>
      </form>
    </Dialog>
  );
}

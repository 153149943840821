import firebase from 'firebase/app';
import { useState, useEffect } from 'react';

export const useUser = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsbscribe = firebase.auth().onAuthStateChanged(
      (user) => {
        setUser(user);
        setLoading(false);
      }
    );
    return () => unsbscribe();
  });

  return { user, loading };
}
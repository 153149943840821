import React from 'react';

import { Main } from './Main';


export const Profile = (props) => (
	<Main user={props.user}>
		<h2>Profile</h2>
		<p>User Profile: {props.user.displayName}</p>
	</Main>
)
export const Phone = (props) => (
	<Main user={props.user}>
		<h2>Phone</h2>
		<p>User Phone</p>
	</Main>
)

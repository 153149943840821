export const TimestampFormat = (d) => {
  if (!d) { return ''; }
  const options = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
  };
  return new Intl.DateTimeFormat('default', options).format(d.toDate());
}

export const DateFormat = (d) => {
  if (!d) { return ''; }
  return new Intl.DateTimeFormat('default').format(d.toDate());
}
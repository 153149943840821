import firebase from 'firebase/app';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import FolderIcon from '@material-ui/icons/Folder';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';

import { Copyright } from '../components/Copyright';
import { RoleInfo } from '../components/Master';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    zIndex: theme.zIndex.drawer + 1, //

  },
  appBarTitle: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export const Main = ({ user, pid, project, children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const history = useHistory();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <>
      <List>
        <ListItem key="title">
          <ListItemIcon><LocalHospitalOutlinedIcon /></ListItemIcon>
          <ListItemText primary="ストレスチェック" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button key="home" onClick={() => history.push('/project')}>
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary="ホーム" secondary={user.displayName} />
        </ListItem>
      </List>
      {pid && (
        <>
          <Divider />
          <List>
            <ListItem button key="project" onClick={() => history.push(`/project/${pid}`)}>
              <ListItemIcon><FolderIcon /></ListItemIcon>
              <ListItemText primary="プロジェクト" secondary={project && project.name} />
            </ListItem>
            <ListItem button key="member" onClick={() => history.push(`/project/${pid}/member`)}>
              <ListItemIcon><PeopleIcon /></ListItemIcon>
              <ListItemText primary="メンバー" />
            </ListItem>
          </List>
        </>
      )}
      {user && <Divider />}
      {user && (
        <List>
          {/* <ListItem button key="profile" onClick={() => history.push('/user/profile')}>
            <ListItemIcon><InboxIcon /></ListItemIcon>
            <ListItemText primary="プロフィール" />
          </ListItem> */}
          <ListItem button key="signout" onClick={() => firebase.auth().signOut()}>
            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
            <ListItemText primary="サインアウト" />
          </ListItem>
        </List>
      )}
      <Divider />
      <Copyright />
    </>
  );

  const roleInfo = project && RoleInfo[project.roles[user.email]];

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Typography className={classes.appBarTitle} variant="h6" noWrap>
            {(project && project.name) || 'ストレスチェック'}
          </Typography>
          {project && roleInfo && (
            <Chip
              icon={<FontAwesomeIcon size="lg" icon={roleInfo.icon} />}
              label={roleInfo.name}
              color="secondary"
            />
          )}
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}


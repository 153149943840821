import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { ThemeProvider } from '@material-ui/core/styles';
import defaultTheme from './components/Theme';

import { Home, About, Friends } from './views/Home';
import { Profile, Phone } from './views/User';
import { SignIn } from './views/Auth';
import { SignUp } from './views/Signup';
import { PasswordReset } from './views/PasswordReset';
import { RoleListView } from './views/Role';
import { ProjectListView, ProjectView } from './views/Project';
import { SubjectView } from './views/Subject';
import { ReportView, ReportCreateView, ReportUpdateView } from './views/Report';

import { useUser } from './hooks/User';
import { Questionnaire, QuestionnaireSubmit } from './components/Questionnaire';
import { Interview, InterviewSubmit } from './components/Interview';

const App = () => {
  const { user, loading } = useUser();

  if (loading) {
    return null;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/about' component={About} />
          <Route exact path='/friends' component={Friends} />
          <Route exact path='/signup' component={SignUp} />
          <Route exact path='/passwordreset' component={PasswordReset} />
          {/* ユーザ関連 */}
          <PrivateRoute exact path='/user/profile' component={Profile} user={user} />
          <PrivateRoute exact path='/user/phone' component={Phone} user={user} />
          {/* プロジェクト関連 */}
          <PrivateRoute exact path='/project' component={ProjectListView} user={user} />
          <PrivateRoute exact path='/project/:id' component={ProjectView} user={user} />
          <PrivateRoute exact path='/project/:id/member' component={RoleListView} user={user} />
          {/* サブジェクト関連 */}
          <PrivateRoute exact path='/project/:id/subject/:sid' component={SubjectView} user={user} />
          {/* 調査票 */}
          <Route exact path='/questionnaire/:id'><Questionnaire /></Route>
          <Route exact path='/questionnaire/:id/submit'><QuestionnaireSubmit /></Route>
          <Route exact path='/interview/:id'><Interview /></Route>
          <Route exact path='/interview/:id/submit'><InterviewSubmit /></Route>
          {/* 面接指導結果報告書入力 */}
          <PrivateRoute exact path='/project/:pid/subject/:sid/report/create' component={ReportCreateView} user={user} />
          <PrivateRoute exact path='/project/:pid/subject/:sid/report/:rid' component={ReportView} user={user} />
          <PrivateRoute exact path='/project/:pid/subject/:sid/report/:rid/update' component={ReportUpdateView} user={user} />
          {/* 404ページ */}
          <Route path="*">Page not found.</Route>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

const PrivateRoute = ({ children, user, component, ...rest }) => {
  // 認証状態でない場合は SignIn ページを表示
  if (!user) {
    return <SignIn />
  }

  // 認証状態の場合は children を表示(userプロパティを設定)
  if (children) {
    return (
      <Route
        {...rest}
        render={() => React.cloneElement(children, { user: user })}
      />
    )
  }

  // childrenが無い場合は、componentを表示(userプロパティを設定)
  const Child = component; // 変数名の先頭を大文字にする
  return (
    <Route
      {...rest}
      render={() => <Child user={user} />}
    />
  )
}

export default App;

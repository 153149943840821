import { faUserEdit, faUserTie, faUserMd } from '@fortawesome/free-solid-svg-icons';

// ロールのマスタ
export const RoleInfo = {
  admin: { name: '実施者', icon: faUserEdit },
  operator: { name: '事業者', icon: faUserTie },
  doctor: { name: '面接指導担当医', icon: faUserMd },
};

// 調査票の項目マスタ
//   type:1 高ポイントほど高ストレス
//   type:0 高ポイントほど低ストレス
export const QuestionMaster = [
  {
    id: "A",
    text: "あなたの仕事についてうかがいます。最もあてはまるものに○を付けてください。",
    options: ["そうだ", "まあそうだ", "ややちがう", "ちがう"],
    group: [
      {
        questions: [
          {id: "A-0-0",  type: 1, text: "非常にたくさんの仕事をしなければならない" },
          {id: "A-0-1",  type: 1, text: "時間内に仕事が処理しきれない" },
          {id: "A-0-2",  type: 1, text: "一生懸命働かなければならない" },
          {id: "A-0-3",  type: 1, text: "かなり注意を集中する必要がある" },
          {id: "A-0-4",  type: 1, text: "高度の知識や技術が必要なむずかしい仕事だ" },
          {id: "A-0-5",  type: 1, text: "勤務時間中はいつも仕事のことを考えていなければならない" },
          {id: "A-0-6",  type: 1, text: "からだを大変よく使う仕事だ" },
          {id: "A-0-7",  type: 0, text: "自分のペースで仕事ができる" },
          {id: "A-0-8",  type: 0, text: "自分で仕事の順番・やり方を決めることができる" },
          {id: "A-0-9",  type: 0, text: "職場の仕事の方針に自分の意見を反映できる" },
          {id: "A-0-10", type: 1, text: "自分の技能や知識を仕事で使うことが少ない" },
          {id: "A-0-11", type: 1, text: "私の部署内で意見のくい違いがある" },
          {id: "A-0-12", type: 1, text: "私の部署と他の部署とはうまが合わない" },
          {id: "A-0-13", type: 0, text: "私の職場の雰囲気は友好的である" },
          {id: "A-0-14", type: 1, text: "私の職場の作業環境（騒音、照明、温度、換気など）はよくない" },
          {id: "A-0-15", type: 0, text: "仕事の内容は自分にあっている" },
          {id: "A-0-16", type: 0, text: "働きがいのある仕事だ" }
        ],
      },
    ],
  },
  {
    id: "B",
    text: "最近1か月間のあなたの状態についてうかがいます。最もあてはまるものに○を付けてください。",
    options: ["ほとんどなかった", "ときどきあった", "しばしばあった", "ほとんどいつもあった"],
    group: [
      {
        questions: [
          {id: "B-0-0",  type: 1, text: "活気がわいてくる" },
          {id: "B-0-1",  type: 1, text: "元気がいっぱいだ" },
          {id: "B-0-2",  type: 1, text: "生き生きする" },
          {id: "B-0-3",  type: 0, text: "怒りを感じる" },
          {id: "B-0-4",  type: 0, text: "内心腹立たしい" },
          {id: "B-0-5",  type: 0, text: "イライラしている" },
          {id: "B-0-6",  type: 0, text: "ひどく疲れた" },
          {id: "B-0-7",  type: 0, text: "へとへとだ" },
          {id: "B-0-8",  type: 0, text: "だるい" },
          {id: "B-0-9",  type: 0, text: "気がはりつめている" },
          {id: "B-0-10", type: 0, text: "不安だ" },
          {id: "B-0-11", type: 0, text: "落着かない" },
          {id: "B-0-12", type: 0, text: "ゆううつだ" },
          {id: "B-0-13", type: 0, text: "何をするのも面倒だ" },
          {id: "B-0-14", type: 0, text: "物事に集中できない" },
          {id: "B-0-15", type: 0, text: "気分が晴れない" },
          {id: "B-0-16", type: 0, text: "仕事が手につかない" },
          {id: "B-0-17", type: 0, text: "悲しいと感じる" },
          {id: "B-0-18", type: 0, text: "めまいがする" },
          {id: "B-0-19", type: 0, text: "体のふしぶしが痛む" },
          {id: "B-0-20", type: 0, text: "頭が重かったり頭痛がする" },
          {id: "B-0-21", type: 0, text: "首筋や肩がこる" },
          {id: "B-0-22", type: 0, text: "腰が痛い" },
          {id: "B-0-23", type: 0, text: "目が疲れる" },
          {id: "B-0-24", type: 0, text: "動悸や息切れがする" },
          {id: "B-0-25", type: 0, text: "胃腸の具合が悪い" },
          {id: "B-0-26", type: 0, text: "食欲がない" },
          {id: "B-0-27", type: 0, text: "便秘や下痢をする" },
          {id: "B-0-28", type: 0, text: "よく眠れない" }
        ],
      },
    ],
  },
  {
    id: "C",
    text: "あなたの周りの方々についてうかがいます。最もあてはまるものに○を付けてください。",
    options: ["非常に", "かなり", "多少", "全くない"],
    group: [
      {
        text: "次の人たちはどのくらい気軽に話ができますか？",
        questions: [
          {id: "C-0-0", type: 0, text: "上司" },
          {id: "C-0-1", type: 0, text: "職場の同僚" },
          {id: "C-0-2", type: 0, text: "配偶者、家族、友人等" }
        ]
      },
      {
        text: "あなたが困った時、次の人たちはどのくらい頼りになりますか？",
        questions: [
          {id: "C-1-0", type: 0, text: "上司" },
          {id: "C-1-1", type: 0, text: "職場の同僚" },
          {id: "C-1-2", type: 0, text: "配偶者、家族、友人等" }
        ]
      },
      {
        text: "あなたの個人的な問題を相談したら、次の人たちはどのくらいきいてくれますか？",
        questions: [
          {id: "C-2-0", type: 0, text: "上司" },
          {id: "C-2-1", type: 0, text: "職場の同僚" },
          {id: "C-2-2", type: 0, text: "配偶者、家族、友人等" }
        ]
      },
    ],
  },
  {
    id: "D",
    text: "満足度について",
    options: ["満足", "まあ満足", "やや不満足", "不満足"],
    group: [
      {
        questions: [
          {id: "D-0-0", type: 0, text: "仕事に満足だ" },
          {id: "D-0-1", type: 0, text: "家庭生活に満足だ" }
        ],
      },
    ],
  },
];

// ストレスプロフィールの項目マスタ
//   type:1 Answerの素点が高ポイントほど高ストレス
//   type:0 Answerの素点が高ポイントほど低ストレス
//   summarizeAnswerDelegate: Answerの素点に対する尺度ごとの得点を計算する関数
//   rankTable: 換算された得点から評価点（配列インデックスを評価点とする）を決定するテーブル（1 - 5 の5段階で、大きいほうがストレスが低い）
//
// [労働安全衛生法に基づくストレスチェック制度実施マニュアル（令和元年7月改訂）](https://www.mhlw.go.jp/content/000533925.pdf)
// 44ページ　素点換算表
// 199ページ　素点換算手順
export const StressProfileMaster = [
  {
    id: "A",
    text: "ストレスの原因と考えられる因子",
    profiles: [
      {
        id: 'A01', type: 0, text: '心理的な仕事の負担(量)',
        summarizeAnswerDelegate: (a) => { return 15-(a["A-0-0"]+a["A-0-1"]+a["A-0-2"]) },
        rankTable: {
          male: [
            {lower: 0, upper: 0},
            {lower: 12, upper: 12},
            {lower: 10, upper: 11},
            {lower: 8, upper: 9},
            {lower: 6, upper: 7},
            {lower: 3, upper: 5},
          ],
          female: [
            {lower: 0, upper: 0},
            {lower: 12, upper: 12},
            {lower: 10, upper: 11},
            {lower: 7, upper: 9},
            {lower: 5, upper: 6},
            {lower: 3, upper: 4},
          ],
        }
      },
      {
        id: 'A02', type: 0, text: '心理的な仕事の負担(質)',
        summarizeAnswerDelegate: (a) => { return 15-(a["A-0-3"]+a["A-0-4"]+a["A-0-5"]) },
        rankTable: {
          male: [
            {lower: 0, upper: 0},
            {lower: 12, upper: 12},
            {lower: 10, upper: 11},
            {lower: 8, upper: 9},
            {lower: 6, upper: 7},
            {lower: 3, upper: 5},
          ],
          female: [
            {lower: 0, upper: 0},
            {lower: 11, upper: 12},
            {lower: 9, upper: 10},
            {lower: 7, upper: 8},
            {lower: 5, upper: 6},
            {lower: 3, upper: 4},
          ],
        }
      },
      {
        id: 'A03', type: 0, text: '自覚的な身体的負担度',
        summarizeAnswerDelegate: (a) => { return 5-a["A-0-6"] },
        rankTable: {
          male: [
            {lower: 0, upper: 0},
            {lower: 4, upper: 4},
            {lower: 3, upper: 3},
            {lower: 2, upper: 2},
            {lower: 1, upper: 1},
            {lower: 0, upper: 0},
          ],
          female: [
            {lower: 0, upper: 0},
            {lower: 4, upper: 4},
            {lower: 3, upper: 3},
            {lower: 2, upper: 2},
            {lower: 1, upper: 1},
            {lower: 0, upper: 0},
          ],
        }
      },
      {
        id: 'A04', type: 0, text: '職場の対人関係でのストレス',
        summarizeAnswerDelegate: (a) => { return 10-(a["A-0-11"]+a["A-0-12"])+a["A-0-13"] },
        rankTable: {
          male: [
            {lower: 0, upper: 0},
            {lower: 10, upper: 12},
            {lower: 8, upper: 9},
            {lower: 6, upper: 7},
            {lower: 4, upper: 5},
            {lower: 3, upper: 3},
          ],
          female: [
            {lower: 0, upper: 0},
            {lower: 10, upper: 12},
            {lower: 8, upper: 9},
            {lower: 6, upper: 7},
            {lower: 4, upper: 5},
            {lower: 3, upper: 3},
          ],
        }
      },
      {
        id: 'A05', type: 0, text: '職場環境によるストレス',
        summarizeAnswerDelegate: (a) => { return 5-a["A-0-14"] },
        rankTable: {
          male: [
            {lower: 0, upper: 0},
            {lower: 4, upper: 4},
            {lower: 3, upper: 3},
            {lower: 2, upper: 2},
            {lower: 1, upper: 1},
            {lower: 0, upper: 0},
          ],
          female: [
            {lower: 0, upper: 0},
            {lower: 4, upper: 4},
            {lower: 3, upper: 3},
            {lower: 2, upper: 2},
            {lower: 1, upper: 1},
            {lower: 0, upper: 0},
          ],
        }
      },
      {
        id: 'A06', type: 1, text: '仕事のコントロール度',
        summarizeAnswerDelegate: (a) => { return 15-(a["A-0-7"]+a["A-0-8"]+a["A-0-9"]) },
        rankTable: {
          male: [
            {lower: 0, upper: 0},
            {lower: 3, upper: 4},
            {lower: 5, upper: 6},
            {lower: 7, upper: 8},
            {lower: 9, upper: 10},
            {lower: 11, upper: 12},
          ],
          female: [
            {lower: 0, upper: 0},
            {lower: 3, upper: 3},
            {lower: 4, upper: 5},
            {lower: 6, upper: 8},
            {lower: 9, upper: 10},
            {lower: 11, upper: 12},
          ],
        }
      },
      {
        id: 'A07', type: 1, text: '技能の活用度',
        summarizeAnswerDelegate: (a) => { return a["A-0-10"] },
        rankTable: {
          male: [
            {lower: 0, upper: 0},
            {lower: 1, upper: 1},
            {lower: 2, upper: 2},
            {lower: 3, upper: 3},
            {lower: 4, upper: 4},
            {lower: 0, upper: 0},
          ],
          female: [
            {lower: 0, upper: 0},
            {lower: 1, upper: 1},
            {lower: 2, upper: 2},
            {lower: 3, upper: 3},
            {lower: 4, upper: 4},
            {lower: 0, upper: 0},
          ],
        }
      },
      {
        id: 'A08', type: 1, text: '仕事の適性度',
        summarizeAnswerDelegate: (a) => { return 5-a["A-0-15"] },
        rankTable: {
          male: [
            {lower: 0, upper: 0},
            {lower: 1, upper: 1},
            {lower: 2, upper: 2},
            {lower: 3, upper: 3},
            {lower: 0, upper: 0},
            {lower: 4, upper: 4},
          ],
          female: [
            {lower: 0, upper: 0},
            {lower: 1, upper: 1},
            {lower: 2, upper: 2},
            {lower: 3, upper: 3},
            {lower: 0, upper: 0},
            {lower: 4, upper: 4},
          ],
        }
      },
      {
        id: 'A09', type: 1, text: '働きがい',
        summarizeAnswerDelegate: (a) => { return 5-a["A-0-16"] },
        rankTable: {
          male: [
            {lower: 0, upper: 0},
            {lower: 1, upper: 1},
            {lower: 2, upper: 2},
            {lower: 3, upper: 3},
            {lower: 0, upper: 0},
            {lower: 4, upper: 4},
          ],
          female: [
            {lower: 0, upper: 0},
            {lower: 1, upper: 1},
            {lower: 2, upper: 2},
            {lower: 3, upper: 3},
            {lower: 0, upper: 0},
            {lower: 4, upper: 4},
          ],
        }
      },
    ],
  },
  {
    id: "B",
    text: "ストレスによっておこる心身の反応",
    profiles: [
      {
        id: 'B01', type: 1, text: '活気',
        summarizeAnswerDelegate: (a) => { return a["B-0-0"]+a["B-0-1"]+a["B-0-2"] },
        rankTable: {
          male: [
            {lower: 0, upper: 0},
            {lower: 3, upper: 3},
            {lower: 4, upper: 5},
            {lower: 6, upper: 7},
            {lower: 8, upper: 9},
            {lower: 10, upper: 12},
          ],
          female: [
            {lower: 0, upper: 0},
            {lower: 3, upper: 3},
            {lower: 4, upper: 5},
            {lower: 6, upper: 7},
            {lower: 8, upper: 9},
            {lower: 10, upper: 12},
          ],
        }
      },
      {
        id: 'B02', type: 0, text: 'イライラ感',
        summarizeAnswerDelegate: (a) => { return a["B-0-3"]+a["B-0-4"]+a["B-0-5"] },
        rankTable: {
          male: [
            {lower: 0, upper: 0},
            {lower: 10, upper: 12},
            {lower: 8, upper: 9},
            {lower: 6, upper: 7},
            {lower: 4, upper: 5},
            {lower: 3, upper: 3},
          ],
          female: [
            {lower: 0, upper: 0},
            {lower: 11, upper: 12},
            {lower: 9, upper: 10},
            {lower: 6, upper: 8},
            {lower: 4, upper: 5},
            {lower: 3, upper: 3},
          ],
        }
      },
      {
        id: 'B03', type: 0, text: '疲労感',
        summarizeAnswerDelegate: (a) => { return a["B-0-6"]+a["B-0-7"]+a["B-0-8"] },
        rankTable: {
          male: [
            {lower: 0, upper: 0},
            {lower: 11, upper: 12},
            {lower: 8, upper: 10},
            {lower: 5, upper: 7},
            {lower: 4, upper: 4},
            {lower: 3, upper: 3},
          ],
          female: [
            {lower: 0, upper: 0},
            {lower: 12, upper: 12},
            {lower: 9, upper: 11},
            {lower: 6, upper: 8},
            {lower: 4, upper: 5},
            {lower: 3, upper: 3},
          ],
        }
      },
      {
        id: 'B04', type: 0, text: '不安感',
        summarizeAnswerDelegate: (a) => { return a["B-0-9"]+a["B-0-10"]+a["B-0-11"] },
        rankTable: {
          male: [
            {lower: 0, upper: 0},
            {lower: 10, upper: 12},
            {lower: 8, upper: 9},
            {lower: 5, upper: 7},
            {lower: 4, upper: 4},
            {lower: 3, upper: 3},
          ],
          female: [
            {lower: 0, upper: 0},
            {lower: 11, upper: 12},
            {lower: 8, upper: 10},
            {lower: 5, upper: 7},
            {lower: 4, upper: 4},
            {lower: 3, upper: 3},
          ],
        }
      },
      {
        id: 'B05', type: 0, text: '抑うつ感',
        summarizeAnswerDelegate: (a) => { return a["B-0-12"]+a["B-0-13"]+a["B-0-14"]+a["B-0-15"]+a["B-0-16"]+a["B-0-17"] },
        rankTable: {
          male: [
            {lower: 0, upper: 0},
            {lower: 17, upper: 24},
            {lower: 13, upper: 16},
            {lower: 9, upper: 12},
            {lower: 7, upper: 8},
            {lower: 6, upper: 6},
          ],
          female: [
            {lower: 0, upper: 0},
            {lower: 18, upper: 24},
            {lower: 13, upper: 17},
            {lower: 9, upper: 12},
            {lower: 7, upper: 8},
            {lower: 6, upper: 6},
          ],
        }
      },
      {
        id: 'B06', type: 0, text: '身体愁訴',
        summarizeAnswerDelegate: (a) => { return a["B-0-18"]+a["B-0-19"]+a["B-0-20"]+a["B-0-21"]+a["B-0-22"]+a["B-0-23"]+a["B-0-24"]+a["B-0-25"]+a["B-0-26"]+a["B-0-27"]+a["B-0-28"] },
        rankTable: {
          male: [
            {lower: 0, upper: 0},
            {lower: 27, upper: 44},
            {lower: 22, upper: 26},
            {lower: 16, upper: 21},
            {lower: 12, upper: 15},
            {lower: 11, upper: 11},
          ],
          female: [
            {lower: 0, upper: 0},
            {lower: 30, upper: 44},
            {lower: 24, upper: 29},
            {lower: 18, upper: 23},
            {lower: 14, upper: 17},
            {lower: 11, upper: 13},
          ],
        }
      },
    ],
  },
  {
    id: "C",
    text: "ストレス反応に影響を与える他の因子",
    profiles: [
      {
        id: 'C01', type: 0, text: '上司からのサポート',
        summarizeAnswerDelegate: (a) => { return 15-(a["C-0-0"]+a["C-1-0"]+a["C-2-0"]) },
        rankTable: {
          male: [
            {lower: 0, upper: 0},
            {lower: 3, upper: 4},
            {lower: 5, upper: 6},
            {lower: 7, upper: 8},
            {lower: 9, upper: 10},
            {lower: 11, upper: 12},
          ],
          female: [
            {lower: 0, upper: 0},
            {lower: 3, upper: 3},
            {lower: 4, upper: 5},
            {lower: 6, upper: 7},
            {lower: 8, upper: 10},
            {lower: 11, upper: 12},
          ],
        }
      },
      {
        id: 'C02', type: 0, text: '同僚からのサポート',
        summarizeAnswerDelegate: (a) => { return 15-(a["C-0-1"]+a["C-1-1"]+a["C-2-1"]) },
        rankTable: {
          male: [
            {lower: 0, upper: 0},
            {lower: 3, upper: 5},
            {lower: 6, upper: 7},
            {lower: 8, upper: 9},
            {lower: 10, upper: 11},
            {lower: 12, upper: 12},
          ],
          female: [
            {lower: 0, upper: 0},
            {lower: 3, upper: 5},
            {lower: 6, upper: 7},
            {lower: 8, upper: 9},
            {lower: 10, upper: 11},
            {lower: 12, upper: 12},
          ],
        }
      },
      {
        id: 'C03', type: 0, text: '家族・友人からのサポート',
        summarizeAnswerDelegate: (a) => { return 15-(a["C-0-2"]+a["C-1-2"]+a["C-2-2"]) },
        rankTable: {
          male: [
            {lower: 0, upper: 0},
            {lower: 3, upper: 6},
            {lower: 7, upper: 8},
            {lower: 9, upper: 9},
            {lower: 10, upper: 11},
            {lower: 12, upper: 12},
          ],
          female: [
            {lower: 0, upper: 0},
            {lower: 3, upper: 6},
            {lower: 7, upper: 8},
            {lower: 9, upper: 9},
            {lower: 10, upper: 11},
            {lower: 12, upper: 12},
          ],
        }
      },
      {
        id: 'C04', type: 0, text: '仕事や生活の満足度',
        summarizeAnswerDelegate: (a) => { return 10-(a["D-0-0"]+a["D-0-1"]) },
        rankTable: {
          male: [
            {lower: 0, upper: 0},
            {lower: 2, upper: 3},
            {lower: 4, upper: 4},
            {lower: 5, upper: 6},
            {lower: 7, upper: 7},
            {lower: 8, upper: 8},
          ],
          female: [
            {lower: 0, upper: 0},
            {lower: 2, upper: 3},
            {lower: 4, upper: 4},
            {lower: 5, upper: 6},
            {lower: 7, upper: 7},
            {lower: 8, upper: 8},
          ],
        }
      },
    ],
  },
]

export const questionnaireMailBody = ({ user, project, subject }) => {
var from = '';
console.log(`"${project.contact_name}"`)
if (project.contact_name == null) {
  from = 'ストレスチェック実施者です。'
} else {
  from = `ストレスチェック実施者の、${project.contact_belongs}　${project.contact_name} です。`
}
var contact = '';
if (project.contact_name == null) {
contact = ''
} else {
contact = `
もしくは
${project.contact_belongs}　${project.contact_name}　（${project.contact_email}）
`;
}

return `
${project.name}
${subject.name} 様

平素より会社の健康・衛生管理施策にご協力いただき、誠にありがとうございます。
${from}

「${project.name}」におけるストレスチェックを実施します。

以下のURLのWEBサイトにて、職業性ストレス簡易調査票に回答してください。
URL: {{url}}

職業性ストレス簡易調査票を用いて、あなたの職場におけるストレスレベルを測定します。
質問は全部で57問です。(所要時間5分間)

ご不明な点がありましたら、
${user.email}
${contact}
まで、ご遠慮なくご連絡下さい。

以上、よろしくお願い申し上げます。


【ストレスチェックの目的】
会社では従来より、心の健康管理の一環として、定期健康診断における問診を始めとし
産業医・保健師への相談窓口設置やメンタルヘルス研修等を行っておりますが、
今般、従来施策とは別のものとして、セルフケア(一人ひとりが行う自身の健康管理)の
さらなる充実化および働きやすい職場環境の形成を目的に、労働安全衛生法に基づき、
産業医・保健師を実施者としたストレスチェックを実施しています。

【受検対象者】
上記の目的から、できるだけ多くの社員(できれば対象者全員)に実施していただきますよう、
お願い申し上げます。ただし、今回のストレスチェックを受けない場合でも、
会社側からの不利益な取扱い等は一切ございません。

【ご回答いただいたデータの取扱い】
ご回答いただいた個人のストレスチェック結果については、ご回答直後からご自身で
確認・閲覧・印刷できますので、自己管理にお役立て下さい。 
ご回答いただいた個人のストレスチェック結果に基づき、個人の健康管理を目的として
産業医・保健師のみが確認し、必要に応じて面接推奨のご連絡を個別に差し上げます。
個人の結果が外部(上司・人事部門等)に漏れることは、一切ありません。 
また、職場全体のストレス傾向の把握を目的に、個人が特定できないようストレスチェック結果を
加工し、分析および報告書作成に使用します。

`;
}

export const interviewMailBody = ({ user, project, subject }) => {
var from = '';
console.log(`"${project.contact_name}"`)
if (project.contact_name == null) {
  from = 'ストレスチェック実施者です。'
} else {
  from = `ストレスチェック実施者の、${project.contact_belongs}　${project.contact_name} です。`
}
var contact = '';
if (project.contact_name == null) {
contact = ''
} else {
contact = `
もしくは
${project.contact_belongs}　${project.contact_name}　（${project.contact_email}）
`;
}

return `
${project.name}
${subject.name} 様

${from}

今回のストレスチェックの結果、あなたのストレス度が高いとの結果でしたので、個別にご連絡しております。
(個別結果については別途送付したストレスプロフィールをご確認ください)

ストレスチェックを行った時点と、その直前1ヶ月程度の状態が反映されているという条件ですが、あなたのストレスバランスが崩れている可能性がありますので、心配しています。
現在の心身の状態はいかがでしょうか。もし何らかの不調やストレスの存在を自覚されるようでしたら、「ストレスチェックに基づく産業医面接」を強くお勧めします。

以下のURLのWEBサイトにて、面接指導の申し込み有無をお伝えください。
URL: {{url}}

ご不明な点がありましたら、
${user.email}
${contact}
まで、ご遠慮なくご連絡下さい。

以上、よろしくお願い申し上げます。

`;
}

// export default QuestionMaster;

import firebase from 'firebase/app';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import crypto from 'crypto';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import AddIcon from '@material-ui/icons/Add';

import { useSubjectList } from '../hooks/Subject';
import { useReportList } from '../hooks/Report';
import { useDetail } from '../hooks/Detail';
import { withCommonStyles } from './Style';
import { ButtonMenu, SplitButtonMenu } from './ButtonMenu';
import { TimestampFormat } from './Format';
import { questionnaireMailBody, interviewMailBody } from './Master'

import csv from 'csv';
// import parse from 'csv-parse';
import fileReaderStream from 'filereader-stream';

const useStyles = makeStyles(theme => withCommonStyles(theme, {
  doc_title_bar: {
    margin: theme.spacing(0, 0, 2),
  },
  doc_title: {
    flexGrow: 1,
  },
  box: {
    margin: theme.spacing(2, 0, 2),
  },
  doc_box: {
    margin: theme.spacing(4, 0, 4),
  },
  alert_box: {
    padding: theme.spacing(2),
    background: theme.palette.secondary.main,
  },
}));

const DetailTableRow = ({ pid, sid, subject, children }) => {
  const history = useHistory();
  const { reportList } = useReportList(pid, sid);
  const { detail } = useDetail(pid, sid);

  const statusText = subjectStatus({ subject: subject, detail: detail, reports: reportList });
  const actionText = statusMaster[statusText].action_text;
  const StatusIcon = statusMaster[statusText].icon;

  return (
    <TableRow hover onClick={() => history.push(`/project/${pid}/subject/${sid}`)} >
      <Tooltip placement="top" title={actionText}>
        <TableCell align="center" padding="checkbox">
          <StatusIcon />
        </TableCell>
      </Tooltip>
      <TableCell>{subject.id} {subject.name}</TableCell>
      <Tooltip placement="top" title={actionText}>
        <TableCell>{statusText}</TableCell>
      </Tooltip>
      <Tooltip placement="top" title="ストレスの要因（低いほど高ストレス）">
        <TableCell align="right">{detail && detail.score && detail.score.A}</TableCell>
      </Tooltip>
      <Tooltip placement="top" title="心身のストレス反応（低いほど高ストレス）">
        <TableCell align="right">{detail && detail.score && detail.score.B}</TableCell>
      </Tooltip>
      <Tooltip placement="top" title="周囲のサポート（低いほど高ストレス）">
        <TableCell align="right">{detail && detail.score && detail.score.C}</TableCell>
      </Tooltip>
      <TableCell align="right">
        <ButtonMenu id={`subject-menu-${sid}`}>
          {children}
        </ButtonMenu>
      </TableCell>
    </TableRow>
  )
}

export const SubjectListPaper = ({ user, pid, project }) => {
  const classes = useStyles();
  const history = useHistory();
  const { subjectList } = useSubjectList(user.email, pid, project);

  const [sendMail, setSendMail] = useState(false);
  const [addMulti, setAddMulti] = useState(false);
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(null);
  const [del, setDel] = useState(null);

  return (
    <Paper className={classes.paper}>
      <Box className={classes.command} display="flex" flexDirection="row-reverse">
        <SplitButtonMenu
          id="split-button-menu"
          color="secondary"
          label="受検者を追加"
          startIcon={<AddIcon />}
          onClick={() => { setAdd(true) }}
        >
          <MenuItem onClick={e => { setSendMail(true) }}>受験案内メール一括送信</MenuItem>
          <MenuItem onClick={e => { setAddMulti(true) }}>受検者一括インポート</MenuItem>
        </SplitButtonMenu>
      </Box>

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="center" padding="checkbox">&nbsp;</TableCell>
            <TableCell align="left">受検者氏名</TableCell>
            <TableCell align="left">状態</TableCell>
            <TableCell align="right">評価点(A)</TableCell>
            <TableCell align="right">評価点(B)</TableCell>
            <TableCell align="right">評価点(C)</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subjectList.map(row => (
            <DetailTableRow key={row.id} pid={pid} sid={row.id} subject={row.data()} >
              <MenuItem onClick={e => { history.push(`/project/${pid}/subject/${row.id}`) }}>詳細</MenuItem>
              <MenuItem onClick={e => { setEdit(row) }}>編集</MenuItem>
              <MenuItem onClick={e => { setDel(row) }}>削除</MenuItem>
            </DetailTableRow>
          ))}
        </TableBody>
      </Table>
      <SendMailDialog user={user} pid={pid} project={project} open={sendMail} onClose={() => setSendMail(false)} />
      <AddMultiDialog pid={pid} open={addMulti} onClose={() => setAddMulti(false)} />
      <AddDialog pid={pid} open={add} onClose={() => setAdd(false)} />
      <EditDialog pid={pid} sid={edit && edit.id} subject={edit && edit.data()} open={edit !== null} onClose={() => setEdit(null)} />
      <DeleteDialog pid={pid} sid={del && del.id} subject={del && del.data()} open={del !== null} onClose={() => setDel(null)} />
    </Paper>
  );
}

const questionnaireSendmail = async (user, pid, project, sid, subject) => {
  const data = {
    type: 'questionnaire',
    user: user.uid,
    project: pid,
    subject: sid,
    sex: subject.sex, // ストレスプロフィール算出に必要（調査票送信時に利用）
    // App.js のURLルーティング参照
    // サーバ側で自動生成されたドキュメントID(qid)を付加する
    urlprefix: `${window.location.protocol}//${window.location.host}/questionnaire/`,
    message: {
      to: subject.email,
      from: 'noreplay@stcheck.mtdev.net',
      subject: `【ストレスチェックのご案内】${project.name}`,
      // {{url}} はサーバ側でURL文字列に置換される
      text: questionnaireMailBody({ user: user, project: project, subject: subject }),
    },
    created_at: firebase.firestore.FieldValue.serverTimestamp(),
  };

  // questionnairesドキュメントの作成時に Cloud Function でメールが送信される
  await firebase.firestore().collection('messages').add(data);

  // questionnairesドキュメント作成後、subjectに受検案内メール送信済みフラグを立てる
  await firebase.firestore().collection('projects').doc(pid)
    .collection('details').doc(sid).set({
      questionnaire_mail_sent: true,
      questionnaire_mail_sent_at: firebase.firestore.FieldValue.serverTimestamp(),
    }, { merge: true });
}

const interviewSendmail = async (user, pid, project, sid, subject) => {
  const data = {
    type: 'interview',
    user: user.uid,
    project: pid,
    subject: sid,
    sex: subject.sex,
    // App.js のURLルーティング参照
    // サーバ側で自動生成されたドキュメントID(qid)を付加する
    urlprefix: `${window.location.protocol}//${window.location.host}/interview/`,
    message: {
      to: subject.email,
      from: 'noreplay@stcheck.mtdev.net',
      subject: `【ストレスチェック後の面接指導のご案内】${project.name}`,
      // {{url}} はサーバ側でURL文字列に置換される
      text: interviewMailBody({ user: user, project: project, subject: subject }),
    },
    created_at: firebase.firestore.FieldValue.serverTimestamp(),
  };

  // interviews Cloud Function でメールが送信される
  await firebase.firestore().collection('messages').add(data);

  // interviewsドキュメント作成後、subjectに面接指導案内メール送信済みフラグを立てる
  await firebase.firestore().collection('projects').doc(pid)
    .collection('details').doc(sid).set({
      interview_mail_sent: true,
      interview_mail_sent_at: firebase.firestore.FieldValue.serverTimestamp(),
    }, { merge: true });
}

export const SubjectPaper = ({ user, pid, project, sid, subject, detail }) => {
  const classes = useStyles();
  const history = useHistory();
  const [edit, setEdit] = React.useState(null);
  const [del, setDel] = React.useState(null);
  const [preReport, setPreReport] = React.useState(null);

  const handleSendmail = (e) => {
    questionnaireSendmail(user, pid, project, sid, subject);
  }

  const handleInterviewSendmail = (e) => {
    interviewSendmail(user, pid, project, sid, subject);
  };

  const role = project.roles[user.email];

  const menuItemVisible = (itemName, role, subject, detail) => {
    let visible = false;
    // 事業者と面接指導担当医に対しては、面接指導申込者のみ閲覧できている前提

    switch (itemName) {
      case '変更':
        if (['admin', 'operator'].includes(role)) {
          visible = true;
        }
        break;
      case '削除':
        if (['admin', 'operator'].includes(role)) {
          visible = true;
        }
        break;
      case '受検案内メール送信':
        if (['admin'].includes(role) && (!detail || (detail && !detail.answered_at))) {
          visible = true;
        }
        break;
      case '面接指導案内メール送信':
        if (['admin'].includes(role) && detail && !detail.interview_requested_at) {
          visible = true;
        }
        break;
      case '面接指導事前情報を入力':
        if (subject && subject.interview_request && detail && detail.interview_requested_at) {
          visible = true;
        }
        break;
      case '面接指導結果報告書を入力':
        // 面接指導結果報告書は複数回分入力できるので、既に入力済みかどうかは条件に含めない
        if (subject && subject.interview_request && detail && detail.interview_requested_at) {
          visible = true;
        }
        break;
      default:
        visible = false;
    }
    return visible;
  };

  return (
    <Paper>
      <Box className={classes.box + ' doc_fields_box'}>
        <Box className={classes.doc_title_bar}>
          <Toolbar>
            <Typography className={classes.doc_title}>{subject.name}</Typography>
            <ButtonMenu id="doc-menu">
              {menuItemVisible('変更', role, subject, detail) && <MenuItem onClick={e => { setEdit(subject) }}>変更</MenuItem>}
              {menuItemVisible('削除', role, subject, detail) && <MenuItem onClick={e => { setDel(subject) }}>削除</MenuItem>}
              {menuItemVisible('受検案内メール送信', role, subject, detail) && <MenuItem onClick={handleSendmail}>受検案内メール送信</MenuItem>}
              {menuItemVisible('面接指導案内メール送信', role, subject, detail) && <MenuItem onClick={handleInterviewSendmail}>面接指導案内メール送信</MenuItem>}
              {menuItemVisible('面接指導事前情報を入力', role, subject, detail) && <MenuItem onClick={e => { setPreReport(subject) }}>面接指導事前情報を入力</MenuItem>}
              {menuItemVisible('面接指導結果報告書を入力', role, subject, detail) && <MenuItem onClick={e => { history.push(`/project/${pid}/subject/${sid}/report/create`) }}>面接指導結果報告書を入力</MenuItem>}
            </ButtonMenu>
          </Toolbar>
          <Divider />
        </Box>
        <Grid container className={'doc_fields_grid_container'} spacing={2}>
          <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>ID(社員番号等):</Grid>
          <Grid item className={'doc_fields_grid_item_value'} xs={9}>{subject.id}</Grid>
          <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>氏名:</Grid>
          <Grid item className={'doc_fields_grid_item_value'} xs={9}>{subject.name}</Grid>
          <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>Emailアドレス:</Grid>
          <Grid item className={'doc_fields_grid_item_value'} xs={9}>{subject.email}</Grid>
          <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>性別:</Grid>
          {(() => {
            let value = '';
            if (subject.sex === 'male') {
              value = '男性';
            } else if (subject.sex === 'female') {
              value = '女性';
            }
            return (<Grid item className={'doc_fields_grid_item_value'} xs={9}>{value}</Grid>);
          })()}
          <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>登録日:</Grid>
          <Grid item className={'doc_fields_grid_item_value'} xs={9}>{TimestampFormat(subject.created_at)}</Grid>
          <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>受検案内メール送信日時:</Grid>
          <Grid item className={'doc_fields_grid_item_value'} xs={9}>{TimestampFormat(detail && detail.questionnaire_mail_sent_at)}</Grid>
          <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>回答日時:</Grid>
          <Grid item className={'doc_fields_grid_item_value'} xs={9}>{TimestampFormat(detail && detail.answered_at)}</Grid>
          <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>評価点(A) ストレスの要因:</Grid>
          <Tooltip placement="left-start" title="低いほど高ストレス">
            <Grid item className={'doc_fields_grid_item_value'} xs={9}>{detail && detail.score && detail.score.A}</Grid>
          </Tooltip>
          <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>評価点(B) 心身のストレス反応:</Grid>
          <Tooltip placement="left-start" title="低いほど高ストレス">
            <Grid item className={'doc_fields_grid_item_value'} xs={9}>{detail && detail.score && detail.score.B}</Grid>
          </Tooltip>
          <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>評価点(C) 周囲のサポート:</Grid>
          <Tooltip placement="left-start" title="低いほど高ストレス">
            <Grid item className={'doc_fields_grid_item_value'} xs={9}>{detail && detail.score && detail.score.C}</Grid>
          </Tooltip>
          <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>高ストレス者:</Grid>
          <Tooltip placement="left-start" title={
            <>
              以下のいずれかを満たす場合に高ストレス者とする。<br />
                1. 評価点(B)が12点以下<br />
                2. 評価点(A)と(C)の合計が26点以下かつ(B)が17点以下
            </>
          }>
            <Grid item className={'doc_fields_grid_item_value'} xs={9}>{detail && detail.stress ? '該当する' : '該当しない'}</Grid>
          </Tooltip>
          <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>面接指導メール送信日時:</Grid>
          <Grid item className={'doc_fields_grid_item_value'} xs={9}>{TimestampFormat(detail && detail.interview_mail_sent_at)}</Grid>
          <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>面接指導申出回答日時:</Grid>
          <Grid item className={'doc_fields_grid_item_value'} xs={9}>{TimestampFormat(detail && detail.interview_requested_at)}</Grid>
          <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>面接指導希望:</Grid>
          <Grid item className={'doc_fields_grid_item_value'} xs={9}>{interviewRequestStatus({ subject, detail })}</Grid>
          <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>勤務の状況:</Grid>
          <Grid item className={'doc_fields_grid_item_value'} xs={9}>{subject.kinmu_joukyou}</Grid>
        </Grid>
        <EditDialog pid={pid} sid={sid} subject={edit} open={edit !== null} onClose={() => setEdit(null)} />
        <DeleteDialog pid={pid} sid={sid} subject={del} open={del !== null} onClose={() => setDel(null)} />
        <PreReportDialog pid={pid} sid={sid} subject={preReport} open={preReport !== null} onClose={() => setPreReport(null)} />
      </Box>
    </Paper>
  );
}

export const SubjectStatusPaper = ({ subject, detail, reports }) => {
  const classes = useStyles();
  const statusText = subjectStatus({ subject: subject, detail: detail, reports: reports });
  const status = statusMaster[statusText];

  return (
    <Paper className={classes.alert_box}>
      <p>{`この受検者の状態は「${statusText}」です。`}</p>
      <p>{`${status.action_text}`}</p>
    </Paper>
  );
}

const statusMaster = {
  '要受検案内': {
    action_text: '受検案内メールを送信しましょう。',
    icon: () => <PriorityHighIcon style={{ color: "red" }} />,
  },
  '調査票回答待ち': {
    action_text: '必要であれば受検勧奨しましょう。',
    icon: () => <PriorityHighIcon style={{ color: "red" }} />,
  },
  '調査票回答済み（高ストレス者でない）': {
    action_text: '完了です。',
    icon: () => null,
  },
  '調査票回答済み（高ストレス者）': {
    action_text: '必要であれば面接指導案内メールを送信しましょう。',
    icon: () => <PriorityHighIcon style={{ color: "red" }} />,
  },
  '面接指導申出回答待ち': {
    action_text: '必要であれば面接指導を勧奨しましょう。',
    icon: () => <PriorityHighIcon style={{ color: "red" }} />,
  },
  '面接指導希望しない': {
    action_text: '完了です。',
    icon: () => null,
  },
  '面接指導報告待ち（面接指導希望者）': {
    action_text: '面接指導の手配をしましょう。面接指導結果報告を待ち、必要であれば面接指導担当医に連絡しましょう。',
    icon: () => <PriorityHighIcon style={{ color: "red" }} />,
  },
  '面接指導報告済み': {
    action_text: '完了です。',
    icon: () => null,
  },
};

const subjectStatus = ({ subject, detail, reports }) => {
  /*
  受検案内メール送信済   回答済   高ストレス者    面接指導案内メール送信済      面接指導希望    面接指導申出回答日時    面接指導結果    状態表示
  -                   -       -             -                         -             -                   -             要受検案内
  Y                   -       -             -                         -             -                   -             調査票回答待ち
  Y                   Y       N             -                         -             -                   -             調査票回答済み（高ストレス者でない）
  Y                   Y       Y             -                         -             -                   -             調査票回答済み（高ストレス者）
  Y                   Y       Y             Y                         N             -                   -             面接指導申出回答待ち
  Y                   Y       Y             Y                         N             Y                   -             面接指導希望しない
  Y                   Y       Y             Y                         Y             Y                   -             面接指導報告待ち（面接指導希望者）
  Y                   Y       Y             Y                         Y             Y                   Y             面接指導報告済み
  */

  const questionnaire_mail_sent = detail && detail.questionnaire_mail_sent; // 受検案内メール送信済
  const answered_at = detail && detail.answered_at;             // 回答済
  const stress = detail && detail.stress;                  // 調査票回答済み（高ストレス者）
  const interview_mail_sent = detail && detail.interview_mail_sent      // 面接指導案内メール送信済
  const interview_request = subject && subject.interview_request;     // 面接指導希望
  const interview_requested_at = detail && detail.interview_requested_at;  // 面接指導申出回答日時
  const reported = reports && reports.length > 0;            // 面接指導結果

  let statusText = '要受検案内';

  if (
    questionnaire_mail_sent &&
    answered_at &&
    stress &&
    interview_mail_sent &&
    interview_request &&
    interview_requested_at &&
    reported
  ) {
    statusText = '面接指導報告済み';
  } else if (
    questionnaire_mail_sent &&
    answered_at &&
    stress &&
    interview_mail_sent &&
    interview_request &&
    interview_requested_at
  ) {
    statusText = '面接指導報告待ち（面接指導希望者）';
  } else if (
    questionnaire_mail_sent &&
    answered_at &&
    stress &&
    interview_mail_sent &&
    !interview_request &&
    interview_requested_at
  ) {
    statusText = '面接指導希望しない';
  } else if (
    questionnaire_mail_sent &&
    answered_at &&
    stress &&
    interview_mail_sent &&
    !interview_request
  ) {
    statusText = '面接指導申出回答待ち';
  } else if (
    questionnaire_mail_sent &&
    answered_at &&
    stress
  ) {
    statusText = '調査票回答済み（高ストレス者）';
  } else if (
    questionnaire_mail_sent &&
    answered_at &&
    !stress
  ) {
    statusText = '調査票回答済み（高ストレス者でない）';
  } else if (
    questionnaire_mail_sent
  ) {
    statusText = '調査票回答待ち';
  }
  return statusText;
}

const interviewRequestStatus = ({ subject, detail }) => {
  if (!subject) {
    return '';
  }
  if (subject.interview_request) {
    return '希望する';
  }
  if (detail && detail.interview_requested_at) {
    return '希望しない';
  }
  return '';
}

// https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
// const validEmailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const SendMailDialog = ({ user, pid, project, open, onClose }) => {
  const { handleSubmit } = useForm();

  const onSubmit = async (data) => {
    try {
      const snap = await firebase.firestore().collection('projects').doc(pid).collection('subjects').get();
      snap.forEach(async (doc) => {
        console.log('subjects', doc, doc.data());
        const detail = await firebase.firestore().collection('projects').doc(pid).collection('details').doc(doc.id).get()
        // detailが存在しない、または、存在しつつanswered_atプロパティが無い場合、案内メールを送信
        if (!detail.exists || (detail.exists && !('answered_at' in detail.data()))) {
          questionnaireSendmail(user, pid, project, doc.id, doc.data());
        }
      });
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="form-dialog-title">受検案内メール一括送信</DialogTitle>
        <DialogContent>
          <DialogContentText>
            調査票未回答の受検者全員へ、案内メールを一括送信します。決定ボタンを押してください。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} >キャンセル</Button>
          <Button type="submit" color="primary">決定</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const AddMultiDialog = ({ pid, open, onClose }) => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async (data) => {

    try {
      if (!data || !data.filepath || !data.filepath[0]) {
        throw new Error('invild object');
      }
      const file = data.filepath[0];
      if (file.type !== 'text/csv') {
        throw new Error(`invalid file type: ${file.type}, want text/csv`);
      }

      const stream = fileReaderStream(file)
        .pipe(csv.parse({
          columns: true,
          trim: true,
          skip_lines_with_error: true,
        }))
        .pipe(csv.transform((rec) => {
          rec.sex = rec.sex === '女性' ? 'female' : 'male';
          return rec;
        }));

      stream.on('data', async (data) => {
        if (!data.id || !data.name || !data.email || !data.sex) {
          throw new Error(`invalid line: ${data.id}, ${data.name}, ${data.email}, ${data.sex}`);
        }

        const md5 = crypto.createHash('md5');
        const id = md5.update(data.id).digest('hex');

        await firebase.firestore().collection('projects').doc(pid).collection('subjects').doc(id).set({
          id: data.id,
          name: data.name,
          email: data.email,
          sex: data.sex,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
        });
      });

      stream.on('end', () => {
        onClose();
      });

      stream.on('error', (e) => {
        throw e;
      });

    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="form-dialog-title">受検者の作成</DialogTitle>
        <DialogContent>
          <DialogContentText>
            新しい受検者を一括作成します。受検者の一覧を記載したCSVファイルを選択して、決定ボタンを押して下さい。
          </DialogContentText>
          <TextField
            type="file"
            name="filepath"
            label="CSVファイル"
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={
              register({
                required: 'ファイルを選択して下さい',
              })
            }
            error={errors.name && true}
            helperText={errors.name && errors.name.message}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} >キャンセル</Button>
          <Button type="submit" color="primary">決定</Button>
        </DialogActions>

        <DialogContent>
          <DialogContentText>
            CSVファイルの文字コードはUTF-8にして下さい。<br />
            CSVファイルのカラム形式は次の通りです。一行目はヘッダ行で、二行目以降に追加する受検者を列挙して下さい。
          </DialogContentText>

          <DialogContentText>CSVファイルのサンプル</DialogContentText>
          <Table className={classes.table} size="small">
            <TableBody>
              <TableRow>
                <TableCell>id</TableCell>
                <TableCell>name</TableCell>
                <TableCell>email</TableCell>
                <TableCell>sex</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>0001</TableCell>
                <TableCell>山田 太郎</TableCell>
                <TableCell>taro@example.com</TableCell>
                <TableCell>男性</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>0002</TableCell>
                <TableCell>田中 花子</TableCell>
                <TableCell>hanako@example.com</TableCell>
                <TableCell>女性</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>0003</TableCell>
                <TableCell>川口 哲雄</TableCell>
                <TableCell>tetsuo@example.com</TableCell>
                <TableCell>男性</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} align="center">以下、同様の形式で行を追加して下さい</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
      </form>
    </Dialog>
  );
}

const AddDialog = ({ pid, open, onClose }) => {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async (data) => {
    try {
      const md5 = crypto.createHash('md5');
      const id = md5.update(data.id).digest('hex');

      await firebase.firestore().collection('projects').doc(pid).collection('subjects').doc(id).set({
        id: data.id,
        name: data.name,
        email: data.email,
        sex: data.sex,
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
      });
    } catch (error) {
      console.log(error);
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="form-dialog-title">受検者の作成</DialogTitle>
        <DialogContent>
          <DialogContentText>
            新しい受検者を作成します。受検者の名前を入力して、決定ボタンを押してください。
          </DialogContentText>
          <TextField
            type="text"
            name="id"
            label="ID(社員番号等)"
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={
              register({
                required: 'ID(社員番号等)を入力してください。',
              })
            }
            error={errors.name && true}
            helperText={errors.name && errors.name.message}
          />
          <TextField
            type="text"
            name="name"
            label="氏名"
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={
              register({
                required: '氏名を入力してください。',
              })
            }
            error={errors.name && true}
            helperText={errors.name && errors.name.message}
          />
          <TextField
            type="email"
            name="email"
            label="メールアドレス"
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={
              register({
                required: 'メールアドレスを入力してください。',
                // pattern: {
                //   value: validEmailPattern,
                //   message: 'Emailアドレスの形式が正しくありません。'
                // },
              })
            }
            error={errors.email && true}
            helperText={errors.email && errors.email.message}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">性別</FormLabel>
            <RadioGroup
              aria-label="sex"
              name="sex"
              row
            >
              <FormControlLabel inputRef={register({ required: '性別を選択してください。' })} value="male" control={<Radio />} label="男性" />
              <FormControlLabel inputRef={register({ required: '性別を選択してください。' })} value="female" control={<Radio />} label="女性" />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} >キャンセル</Button>
          <Button type="submit" color="primary">決定</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const EditDialog = ({ pid, sid, subject, open, onClose }) => {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async (data) => {
    try {
      await firebase.firestore().collection('projects').doc(pid)
        .collection('subjects').doc(sid).set({
          name: data.name,
          email: data.email,
          sex: data.sex,
        }, { merge: true });
    } catch (error) {
      console.log(error);
    }
    onClose();
  };

  if (!pid || !sid || !subject) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="form-dialog-title">受検者の変更</DialogTitle>
        <DialogContent>
          <DialogContentText>
            受検者を変更します。変更する値を入力して、決定ボタンを押してください。
          </DialogContentText>
          <TextField
            defaultValue={subject.id}
            type="text"
            name="id"
            label="ID(社員番号等)"
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            disabled
          />
          <TextField
            defaultValue={subject.name}
            type="text"
            name="name"
            label="氏名"
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={
              register({
                required: '氏名を入力してください。',
              })
            }
            error={errors.name && true}
            helperText={errors.name && errors.name.message}
          />
          <TextField
            defaultValue={subject.email}
            type="email"
            name="email"
            label="メールアドレス"
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={
              register({
                required: 'メールアドレスを入力してください。',
                // pattern: {
                //   value: validEmailPattern,
                //   message: 'Emailアドレスの形式が正しくありません。'
                // },
              })
            }
            error={errors.email && true}
            helperText={errors.email && errors.email.message}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">性別</FormLabel>
            <RadioGroup
              defaultValue={subject.sex}
              aria-label="sex"
              name="sex"
              row
            >
              <FormControlLabel inputRef={register({ required: '性別を選択してください。' })} value="male" control={<Radio />} label="男性" />
              <FormControlLabel inputRef={register({ required: '性別を選択してください。' })} value="female" control={<Radio />} label="女性" />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} >キャンセル</Button>
          <Button type="submit" color="primary">決定</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const DeleteDialog = ({ pid, sid, subject, open, onClose }) => {
  const history = useHistory();

  const onSubmit = async (e) => {
    try {
      await firebase.firestore().collection('projects').doc(pid)
        .collection('subjects').doc(sid).delete();
    } catch (error) {
      console.log(error);
    }
    onClose();
    history.push(`/project/${pid}`);
  };

  if (!pid || !sid || !subject) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">受検者の削除</DialogTitle>
      <DialogContent>
        <DialogContentText>
          受検者を削除します。間違いがないかを確認して、決定ボタンを押してください。
        </DialogContentText>
        <DialogContentText>
          {subject.id} {subject.name} ({subject.email})
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} >キャンセル</Button>
        <Button color="primary" onClick={onSubmit}> 決定</Button>
      </DialogActions>
    </Dialog >
  );
}

const PreReportDialog = ({ pid, sid, subject, open, onClose }) => {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async (data) => {
    try {
      await firebase.firestore().collection('projects').doc(pid)
        .collection('subjects').doc(sid).set({
          kinmu_joukyou: data.kinmu_joukyou,
        }, { merge: true });
    } catch (error) {
      console.log(error);
    }
    onClose();
  };

  if (!pid || !sid || !subject) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="form-dialog-title">面接指導事前情報を入力</DialogTitle>
        <DialogContent>
          <DialogContentText>
            面接指導にあたり面接指導担当医に伝える情報を事前に入力します。内容を入力し決定ボタンを押してください。
          </DialogContentText>
          <TextField
            defaultValue={subject.kinmu_joukyou}
            type="text"
            name="kinmu_joukyou"
            label="勤務の状況 (労働時間、労働時間以外の要因)"
            margin="normal"
            fullWidth
            multiline
            rows="5"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={
              register({
                required: '勤務の状況を入力してください。',
              })
            }
            error={errors.kinmu_joukyou && true}
            helperText={errors.kinmu_joukyou && errors.kinmu_joukyou.message}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} >キャンセル</Button>
          <Button type="submit" color="primary">決定</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

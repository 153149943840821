import React from 'react';
import { useParams } from 'react-router-dom'

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { Main } from './Main';
import { RoleListPaper } from '../components/Role';
import { useProject } from '../hooks/Project';

export const RoleListView = ({ user }) => {
  const { id } = useParams();
  const { project } = useProject(id);

  if (!project) {
    return null;
  }

  return (
    <Main user={user} pid={id} project={project}>
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <Typography>プロジェクトメンバーは次の通りです。</Typography>
        </Grid>

        <Grid item xs={12}>
          <RoleListPaper user={user} pid={id} project={project} />

        </Grid>

      </Grid>
    </Main >
  );
}

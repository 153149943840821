import firebase from 'firebase/app';
import React from 'react';
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import FolderIcon from '@material-ui/icons/Folder';
import AddIcon from '@material-ui/icons/Add';

import { withCommonStyles } from './Style';
import { ButtonMenu } from './ButtonMenu';
import { TimestampFormat } from './Format';
import { RoleInfo } from './Master';

const useStyles = makeStyles(theme => withCommonStyles(theme, {
  table: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export const ProjectListPaper = ({ user, list }) => {
  const classes = useStyles();
  const history = useHistory();

  const [add, setAdd] = React.useState(false);
  const [edit, setEdit] = React.useState(null);
  const [del, setDel] = React.useState(null);

  return (
    <Paper className={classes.paper}>
      <Box className={classes.command} display="flex" flexDirection="row-reverse">
        <Button
          color="secondary"
          onClick={() => { setAdd(true) }}
          startIcon={<AddIcon />}
        >
          プロジェクトの作成
      </Button>
      </Box>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="left">プロジェクト名</TableCell>
            <TableCell align="left">役割</TableCell>
            <TableCell align="left">連絡先</TableCell>
            <TableCell align="left">作成日</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map(row => (
            <TableRow key={row.id} hover onClick={(e) => { history.push(`/project/${row.id}`); }}>
              <TableCell align="left" scope="row"><FolderIcon className={classes.icon} /> {row.data().name}</TableCell>
              <TableCell align="left" scope="row"><FontAwesomeIcon size="lg" icon={RoleInfo[row.data().roles[user.email]].icon} /> {RoleInfo[row.data().roles[user.email]].name}</TableCell>
              <TableCell align="left" scope="row">{row.data().contact_name}</TableCell>
              <TableCell align="left">{row.data().created_at && new Intl.DateTimeFormat().format(row.data().created_at.toDate())}</TableCell>
              <TableCell align="right">
                <ButtonMenu id={`project-menu-${row.id}`}>
                  <MenuItem onClick={e => { setEdit(row) }}>プロジェクトの変更</MenuItem>
                  <MenuItem onClick={e => { history.push(`/project/${row.id}/member`) }}>プロジェクトメンバー</MenuItem>
                  <MenuItem onClick={e => { setDel(row) }}>プロジェクトの削除</MenuItem>
                </ButtonMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <AddDialog user={user} open={add} onClose={() => setAdd(false)} />
      <EditRoleDialog user={user} pid={edit && edit.id} project={edit && edit.data()} open={edit !== null} onClose={() => setEdit(null)} />
      <DeleteDialog user={user} pid={del && del.id} project={del && del.data()} open={del !== null} onClose={() => setDel(null)} />
    </Paper>
  );
}


export const ProjectPaper = ({ user, pid, project }) => {
  const classes = useStyles();
  const history = useHistory();
  const [edit, setEdit] = React.useState(false);
  const [del, setDel] = React.useState(false);

  return (
    <Paper className={classes.paper}>
      <Box className={classes.command} display="flex" flexDirection="row-reverse">
        <ButtonMenu id="project-menu">
          <MenuItem onClick={e => { setEdit(true) }}>プロジェクトの変更</MenuItem>
          <MenuItem onClick={e => { history.push(`/project/${pid}/member`) }}>プロジェクトメンバー</MenuItem>
          <MenuItem onClick={e => { setDel(true) }}>プロジェクトの削除</MenuItem>
        </ButtonMenu>
      </Box>
      <Grid container className={'doc_fields_grid_container'} spacing={2}>
        <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>プロジェクト名:</Grid>
        <Grid item className={'doc_fields_grid_item_value'} xs={7}>{project.name}</Grid>
        <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>連絡先担当者:</Grid>
        <Grid item className={'doc_fields_grid_item_value'} xs={7}>{project.contact_belongs} {project.contact_name}</Grid>
        <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>連絡先担当者　Emailアドレス:</Grid>
        <Grid item className={'doc_fields_grid_item_value'} xs={7}>{project.contact_email}</Grid>
        <Grid item className={'doc_fields_grid_item_label'} xs={3} align='right'>登録日:</Grid>
        <Grid item className={'doc_fields_grid_item_value'} xs={7}>{TimestampFormat(project.created_at)}</Grid>
      </Grid>
      <EditRoleDialog user={user} pid={pid} project={project} open={edit} onClose={() => setEdit(false)} />
      <DeleteDialog user={user} pid={pid} project={project} open={del} onClose={() => setDel(false)} />
    </Paper>
  );
}

const AddDialog = ({ user, open, onClose }) => {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async (data) => {
    console.log('onSubmit', data);
    try {
      await firebase.firestore().collection('projects').add({
        name: data.name,
        contact_belongs: data.contact_belongs,
        contact_name: data.contact_name,
        contact_email: data.contact_email,
        members: [user.email],
        roles: { [user.email]: 'admin' },
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
        deleted_at: null,
      });
    } catch (error) {
      console.log(error);
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="form-dialog-title">プロジェクトの作成</DialogTitle>
        <DialogContent>
          <DialogContentText>
            新しいプロジェクトを作成します。プロジェクトの名前を入力して、決定ボタンを押してください。
          </DialogContentText>
          <TextField
            type="text"
            name="name"
            label="プロジェクト名"
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={
              register({
                required: 'プロジェクト名を入力してください。',
              })
            }
            error={errors.name && true}
            helperText={errors.name && errors.name.message}
          />
          <TextField
            type="text"
            name="contact_belongs"
            label="連絡先担当者　所属／肩書"
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={
              register({
                required: '連絡先担当者　所属／肩書を入力してください。',
              })
            }
            error={errors.contact_belongs && true}
            helperText={errors.contact_belongs && errors.contact_belongs.message}
          />
          <TextField
            type="text"
            name="contact_name"
            label="連絡先担当者　氏名"
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={
              register({
                required: '連絡先担当者　氏名を入力してください。',
              })
            }
            error={errors.contact_name && true}
            helperText={errors.contact_name && errors.contact_name.message}
          />
          <TextField
            type="text"
            name="contact_email"
            label="連絡先担当者　Emailアドレス"
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={
              register({
                required: '連絡先担当者　Emailアドレスを入力してください。',
              })
            }
            error={errors.contact_email && true}
            helperText={errors.contact_email && errors.contact_email.message}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="submit" color="primary">決定</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const EditRoleDialog = ({ user, pid, project, open, onClose }) => {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async (data) => {
    try {
      await firebase.firestore().collection('projects').doc(pid).set({
        name: data.name,
        contact_belongs: data.contact_belongs,
        contact_name: data.contact_name,
        contact_email: data.contact_email,
      }, { merge: true });
    } catch (error) {
      console.log(error);
    }
    onClose();
  };

  if (!pid || !project) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="form-dialog-title">プロジェクトの変更</DialogTitle>
        <DialogContent>
          <DialogContentText>
            プロジェクトを変更します。変更する値を入力して、決定ボタンを押してください。
              </DialogContentText>
          <TextField
            defaultValue={project.name}
            type="text"
            name="name"
            label="プロジェクト名"
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={
              register({
                required: 'プロジェクト名を入力してください。',
              })
            }
            error={errors.name && true}
            helperText={errors.name && errors.name.message}
          />
          <TextField
            defaultValue={project.contact_belongs}
            type="text"
            name="contact_belongs"
            label="連絡先担当者　所属／肩書"
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={
              register({
                required: '連絡先担当者　所属／肩書を入力してください。',
              })
            }
            error={errors.contact_belongs && true}
            helperText={errors.contact_belongs && errors.contact_belongs.message}
          />
          <TextField
            defaultValue={project.contact_name}
            type="text"
            name="contact_name"
            label="連絡先担当者　氏名"
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={
              register({
                required: '連絡先担当者　氏名を入力してください。',
              })
            }
            error={errors.contact_name && true}
            helperText={errors.contact_name && errors.contact_name.message}
          />
          <TextField
            defaultValue={project.contact_email}
            type="text"
            name="contact_email"
            label="連絡先担当者　Emailアドレス"
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={
              register({
                required: '連絡先担当者　Emailアドレスを入力してください。',
              })
            }
            error={errors.contact_email && true}
            helperText={errors.contact_email && errors.contact_email.message}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="submit" color="primary">決定</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const DeleteDialog = ({ pid, project, open, onClose }) => {
  const history = useHistory();

  const onSubmit = async (e) => {
    try {
      await firebase.firestore().collection('projects').doc(pid).update({
        deleted_at: firebase.firestore.FieldValue.serverTimestamp(),
      });
    } catch (error) {
      console.log(error);
    }
    onClose();
    history.push('/project');
  };

  if (!pid || !project) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">プロジェクトの削除</DialogTitle>
      <DialogContent>
        <DialogContentText>
          プロジェクトを削除します。間違いがないかを確認して、決定ボタンを押してください。
              </DialogContentText>
        <DialogContentText>
          {project.name}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button color="primary" onClick={onSubmit}> 決定</Button>
      </DialogActions>
    </Dialog >
  );
}

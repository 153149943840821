import React from 'react';
import { Main } from './Main';
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export const Home = () => (
	<Container maxWidth="sm" >
		<Box textAlign="center">
			<Typography variant="h5" component="h1">ストレスチェック</Typography>
			<Typography variant="subtitle1">ようこそ</Typography>
			<Link to="/user/profile">スタート</Link>
		</Box>
	</Container>
)

export const About = () => (
	<Main>
		<h2>About</h2>
		<p>フレンズに投票するページです</p>
	</Main>
)

export const Friends = () => (
	<Main>
		<h2>Friends</h2>
		<p>ここにフレンズのリストを書きます</p>
	</Main>
)

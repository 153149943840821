
import firebase from 'firebase/app';
import { useState, useEffect } from 'react';

export const useReportList = (docid, sid) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reportList, setReportList] = useState(null);

  useEffect(
    () => {
      if (!docid || !sid) {
        return;
      }

      const unsbscribe = firebase.firestore().collection('projects').doc(docid)
        .collection('subjects').doc(sid)
        .collection('reports')
        .orderBy('jisshi_date', 'desc')
        .onSnapshot(
          snapshot => {
            setLoading(false);
            setReportList(snapshot.docs);
          },
          err => {
            setError(err);
          });

      return () => unsbscribe();
    }, [docid, sid]
  );

  return { reportList, error, loading };
}

export const useReport = (pid, sid, rid) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState(null);

  useEffect(
    () => {
      if (!pid || !sid || !rid) {
        return;
      }
      const unsbscribe = firebase.firestore()
        .collection('projects').doc(pid)
        .collection('subjects').doc(sid)
        .collection('reports').doc(rid)
        .onSnapshot(
          snapshot => {
            if (snapshot.exists) {
              setLoading(false);
              setReport(snapshot.data());
            } else {
              setLoading(true);
              setReport(null);
            }
          },
          err => {
            setError(err);
          }
        );

      return () => unsbscribe();
    }, [pid, sid, rid]
  );

  return { report, error, loading };
}

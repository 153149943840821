export const withCommonStyles = (theme, style) => {

  const common = {
    // 表示コンポーネントのベースとなるPaper
    paper: {
      margin: theme.spacing(2, 1, 1, 1),
      padding: theme.spacing(1, 1, 1, 1),
    },
    // テキスト本文中に挿入するアイコン
    icon: {
      verticalAlign: 'bottom',
    },
    // Paperの右上に配置するメニューボタン
    command: {
      padding: theme.spacing(0, 0, 1, 0),
    },
    // テーブル
    table: {
    },
  };

  if (!style) {
    return common;
  }

  return Object.assign(style, common);
}

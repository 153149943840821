import firebase from 'firebase/app';
import { useState, useEffect } from 'react';

export const useProjectList = (email) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);

  useEffect(
    () => {
      const unsbscribe = firebase.firestore().collection('projects')
        .where('deleted_at', '==', null) // deleted_atフィールドが存在するドキュメントは、論理削除されているものとみなし、表示しない
        .where('members', 'array-contains', email)
        .orderBy('name')
        .onSnapshot(
          (snapshot) => {
            const p = [];
            snapshot.forEach((doc) => {
              p.push(doc);
            });
            setLoading(false);
            setProjects(p);
          },
          err => {
            console.log(err);
            setError(err);
          });

      return () => unsbscribe();
    }, [email]
  );

  return { error, loading, projects };
}

export const useProject = (docid) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);

  useEffect(
    () => {
      if (!docid) {
        return;
      }
      const unsbscribe = firebase.firestore().collection('projects').doc(docid).onSnapshot(
        snapshot => {
          if (snapshot.exists) {
            setLoading(false);
            setProject(snapshot.data());
          } else {
            setLoading(true);
            setProject(null);
          }
        },
        err => {
          setError(err);
        });
      return () => unsbscribe();
    }, [docid]
  );
  return { project, error, loading };
}